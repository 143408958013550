import React, { useState } from 'react';
import {
  Box, Button, ButtonGroup, Chip, Divider, Grid, Tab, Tabs, useMediaQuery, useTheme,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import useUiStore, { SELECTION_MODE, TABS_CONFIG } from '../../stores/uiStore';
import BuildingSelectionModeContent from './BuildingSelectionModeContent';
import CameraModeBar from './CameraModeBar';
import BalconyPositionSelectionModeContent from './BalconyPositionSelectionModeContent';
import useViewStore from '../../stores/viewStore';

export default function Sidebar() {
  const [selectedTab, setSelectedTab, selectionMode] = useUiStore(useShallow((state) => [state.selectedTab, state.setSelectedTab, state.selectionMode]));
  const [setFocusOnId] = useViewStore(useShallow((state) => [state.setFocusOnId]));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleTabChange = (tab) => {
    if (selectionMode) {
      return;
    }

    if (tab.id === TABS_CONFIG.SURROUNDING.id) {
      setFocusOnId(null);
    }
    setSelectedTab(tab.id);
  };

  const renderSelectionMode = () => {
    if (selectionMode === SELECTION_MODE.BUILDING) {
      return (<BuildingSelectionModeContent />);
    }
    if (selectionMode === SELECTION_MODE.BALCONY_POSITION) {
      return (<BalconyPositionSelectionModeContent />);
    }
    return 'Unknown selection mode';
  };

  const renderTabContent = () => {
    const tab = TABS_CONFIG[selectedTab];
    if (tab.Component) {
      return <tab.Component />;
    }
    return 'Unknown tab';
  };

  return (
    <Box pb={isMobile ? 1 : 1} display="flex" flexDirection="column" height={isMobile ? '100%' : '100vh'}>
      <Box sx={{
        position: 'sticky', top: 0, zIndex: 1, background: 'white', paddingBottom: 1,
      }}
      >
        <Tabs
          value={selectedTab}
          onChange={(_, value) => handleTabChange(TABS_CONFIG[value])}
          indicatorColor="primary"
          variant="fullWidth"
        >
          {Object.keys(TABS_CONFIG).map((key) => (
            <Tab label={TABS_CONFIG[key].name} value={key} key={key} />
          ))}
        </Tabs>
      </Box>
      <div style={{ flex: 1, overflow: 'auto', overflowX: 'hidden' }}>
        {selectionMode ? renderSelectionMode() : (
          <Grid container spacing={2} px={isMobile ? 0 : 1}>
            <Grid item xs={12} pb={isMobile ? 1 : 0}>
              {renderTabContent()}
            </Grid>
          </Grid>
        )}
      </div>
    </Box>
  );
}
