import React from 'react';
import { Box, Slider, Typography } from '@mui/material';
import useScenarioStore from '../stores/scenarioStore';

export default function BalconyRotationSlider() {
  const { setBalconyDirection, balconyCalculatedRotationY, triggerUpdatedScenarioFlag } = useScenarioStore();

  return (
    <Box>
      <Typography id="balcony-direction-slider" gutterBottom>
        Direction
      </Typography>
      <Box display="flex" justifyContent="center" my={1}>
        <Box
          component="span"
          sx={{
            display: 'inline-block',
            position: 'relative',
          }}
        >
          <Typography sx={{
            position: 'absolute', top: '-20px', left: '50%', transform: 'translateX(-50%)',
          }}
          >
            N
          </Typography>
          <Typography sx={{
            position: 'absolute', right: '-20px', top: '50%', transform: 'translateY(-50%)',
          }}
          >
            E
          </Typography>
          <Typography sx={{
            position: 'absolute', bottom: '-20px', left: '50%', transform: 'translateX(-50%)',
          }}
          >
            S
          </Typography>
          <Typography sx={{
            position: 'absolute', left: '-20px', top: '50%', transform: 'translateY(-50%)',
          }}
          >
            W
          </Typography>
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            style={{
              transform: `rotate(${-(balconyCalculatedRotationY + (Math.PI / 2)) * (180 / Math.PI)}deg)`,
              transformOrigin: 'center',
            }}
          >
            <polygon points="25,0 30,40 25,30 20,40" fill="black" />
          </svg>
        </Box>
      </Box>
    </Box>
  );
}
