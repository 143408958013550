import React from 'react';
import * as THREE from 'three';

function Umbrella({
  topRadius = 0.9,
  topHeight = 0.2,
  handleRadius = 0.02,
  handleHeight = 0.5,
  variant = 'SQUARE',
  open = 1,
  colorHandle,
  colorTop,
}) {
  const calculatedTopRadius = Math.max(0.1, topRadius * open);
  const calculatedTopHeight = topHeight + topHeight * 4 * (1.0 - open);

  if (variant === 'ROUND') {
    // Define the curve for the umbrella's top part
    const points = [];
    for (let y = 0; y < calculatedTopHeight; y += calculatedTopHeight / 4) {
      const x = calculatedTopRadius * Math.sqrt(1 - (y / calculatedTopHeight) ** 1);
      points.push(new THREE.Vector2(x, y));
    }
    for (let y = 0; y < calculatedTopHeight; y += calculatedTopHeight / 4) {
      const x = calculatedTopRadius * 0.05 * Math.sqrt(1 - (y / calculatedTopHeight) ** 1);
      points.push(new THREE.Vector2(x, calculatedTopHeight));
    }

    return (
      <group>
        {/* Top part of the umbrella */}
        <mesh
          position={[0, handleHeight + calculatedTopHeight / 2, 0]}
          castShadow
          receiveShadow
        >
          <latheGeometry args={[points, 32]} />
          <meshStandardMaterial color={colorTop} side={THREE.DoubleSide} />
        </mesh>

        <mesh
          position={[0, (handleHeight + calculatedTopHeight) / 2, 0]}
          castShadow
          receiveShadow
        >
          <cylinderGeometry
            args={[handleRadius, handleRadius, handleHeight + calculatedTopHeight, 32]}
          />
          <meshStandardMaterial color={colorHandle} />
        </mesh>
      </group>
    );
  }

  if (variant === 'SQUARE') {
    return (
      <group>
        {/* Top part of the umbrella */}
        <mesh
          position={[0, handleHeight + topHeight, 0]}
          castShadow
          receiveShadow
        >
          <boxGeometry args={[calculatedTopRadius * 2, 0.02, calculatedTopRadius * 2]} />
          <meshStandardMaterial color={colorTop} />
        </mesh>

        <mesh
          position={[0, (handleHeight + topHeight) / 2, 0]}
          castShadow
          receiveShadow
        >
          <cylinderGeometry
            args={[handleRadius, handleRadius, handleHeight + topHeight, 32]}
          />
          <meshStandardMaterial color={colorHandle} />
        </mesh>
      </group>
    );
  }
}

export default Umbrella;
