import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Divider,
  Collapse, IconButton, Box,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useShallow } from 'zustand/react/shallow';
import usePlantsStore from '../../stores/plantsStore';
import { PLANT_SPECIFICATION } from '../../config/PlantSpecification';
import useShadeCalculation, {
  SHADE_CALCULATION_RANGES,
} from '../../hooks/useShadeCalculation';
import {
  getSunFactor,
  SUN_ATTRIBUTE,
  WATER_ATTRIBUTE,
} from '../../utils/plantAttributeUtils';

function PlantCard({ specification, plant }) {
  const [updatePlantPartly] = usePlantsStore(useShallow((state) => [state.updatePlantPartly]));

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handlePick = () => {
    updatePlantPartly(plant.id, { specificationId: specification.id });
  };

  return (
    <Card>
      <CardMedia
        component="img"
        height="140"
        image={specification.image}
        alt={specification.name}
        onClick={handleExpandClick}
        sx={{ cursor: 'pointer' }}
      />
      <CardContent onClick={handleExpandClick} sx={{ cursor: 'pointer' }}>
        <Typography variant="h6" component="div">
          {specification.name}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Box display="flex" justifyContent="space-between" width="100%">
          {plant.specificationId === specification.id ? (
            <Typography variant="body2" color="text.secondary">
              Selected
            </Typography>
          ) : (
            <Button size="small" onClick={handlePick}>
              Select
            </Button>
          )}
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
          >
            <ExpandMore />
          </IconButton>
        </Box>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph variant="body2" color="text.secondary">
            {specification.info}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default function PlantDetailsPage({ plantId }) {
  const { plants } = usePlantsStore((state) => ({ plants: state.plants }));

  const shadeCalculation = useShadeCalculation();
  const plant = React.useMemo(() => plants.find((p) => p.id === plantId), [plants, plantId]);
  const [attributes, setAttributes] = useState({ WATER: WATER_ATTRIBUTE.MUCH, SUN: SUN_ATTRIBUTE.FULL_SUN });
  const [matchingPlants, setMatchingPlants] = useState([]);
  const [otherPlants, setOtherPlants] = useState([]);

  useEffect(() => {
    const currentAttributes = {
      WATER: WATER_ATTRIBUTE.MUCH,
      SUN: getSunFactor(1.0 - shadeCalculation.calculateShade(plant).totalShade),
    };
    setAttributes(currentAttributes);

    const ratedSpec = PLANT_SPECIFICATION.map((ps) => {
      const { optimalAttributes } = ps;
      const sunRating = optimalAttributes.SUN.includes(currentAttributes.SUN) ? 1 : 0;
      const waterRating = optimalAttributes.WATER.includes(currentAttributes.WATER) ? 1 : 0;
      const spaceRating = optimalAttributes.SPACE.includes(plant.space) ? 1 : 0;
      return {
        ...ps,
        rating: sunRating + waterRating + spaceRating,
      };
    }).sort((a, b) => b.rating - a.rating);

    const newMatchingPlants = ratedSpec.filter((ps) => ps.rating >= 2).slice(0, 3);
    setMatchingPlants(newMatchingPlants);

    const newOtherPlants = PLANT_SPECIFICATION.filter((ps) => !newMatchingPlants.includes(ps));
    setOtherPlants(newOtherPlants);
  }, [plant]);

  if (plants.length === 0 || !plant) {
    return (<div>Plant not found</div>);
  }

  return (
    <div>
      <Typography variant="h5" gutterBottom component="div">
        Current plant location
      </Typography>
      <Typography variant="subtitle1" component="div">
        Water:
        {' '}
        {attributes.WATER.label}
        {' '}
        (range 0-3)
      </Typography>
      <Typography variant="subtitle1" component="div">
        Sun:
        {' '}
        {attributes.SUN.label}
        {' '}
        (range 0-5)
      </Typography>
      <Divider style={{ margin: '20px 0' }} />
      {matchingPlants.length > 0 && (
        <Box mb={2}>
          <Typography variant="h5" gutterBottom component="div">
            Matching Plants
          </Typography>
          <Grid container spacing={2}>
            {matchingPlants && matchingPlants.map((ps) => (
              <Grid item xs={4} key={ps.id}>
                <PlantCard specification={ps} plant={plant} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Typography variant="h5" gutterBottom component="div">
        Other Plants
      </Typography>
      <Grid container spacing={2}>
        {otherPlants && otherPlants.map((ps) => (
          <Grid item xs={6} md={4} key={ps.id}>
            <PlantCard specification={ps} plant={plant} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
