import { useThree } from '@react-three/fiber';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import * as THREE from 'three';
import { useShallow } from 'zustand/react/shallow';
import { useMediaQuery, useTheme } from '@mui/material';
import { ThreeContext } from '../context/ThreeContext';
import useUiStore, { SELECTION_MODE } from '../stores/uiStore';
import useScenarioStore from '../stores/scenarioStore';
import useViewStore from '../stores/viewStore';

export default function useSelectionModeClick() {
  const { gl } = useThree();
  const { camera, scene } = useThree();
  const { selectionMode, setSelectionMode } = useUiStore();
  const { setSelectedBuilding, setTemporarySelectedBuilding } = useScenarioStore();
  const lastClickTime = useRef(0);
  const mousedownTime = useRef(0);
  const mouseupTime = useRef(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const getIntersects = (event) => {
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const div = document.getElementById('threejs-div');
    const rect = div.getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -(((event.clientY - (isMobile ? 0 : 50)) - rect.top) / (rect.height * 1)) * 2 + 1;
    console.log(rect);
    console.log(event.clientY);
    raycaster.setFromCamera(mouse, camera);
    return raycaster.intersectObjects(scene.children, true);
  };

  const handleBuildingSelection = (intersects) => {
    if (intersects[0]?.object?.userData?.category === 'building') {
      setTemporarySelectedBuilding(intersects[0].object.userData);
    }
  };

  function handleClick(event) {
    const clickDuration = mouseupTime.current - mousedownTime.current;
    const timeSinceLastClick = Date.now() - lastClickTime.current;
    if (clickDuration > 200 || timeSinceLastClick < 300) {
      return;
    }
    lastClickTime.current = Date.now();

    if (!selectionMode) {
      return;
    }
    const intersects = getIntersects(event);
    if (selectionMode === SELECTION_MODE.BUILDING) {
      handleBuildingSelection(intersects);
    }
  }

  useEffect(() => {
    gl.domElement.addEventListener('click', handleClick);
    gl.domElement.addEventListener('mousedown', () => {
      mousedownTime.current = Date.now();
    });

    gl.domElement.addEventListener('mouseup', () => {
      mouseupTime.current = Date.now();
    });

    return () => {
      gl.domElement.removeEventListener('click', handleClick);
      gl.domElement.removeEventListener('mousedown', handleClick);
      gl.domElement.removeEventListener('mouseup', handleClick);
    };
  }, [selectionMode]);

  return { handleClick };
}
