import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import {
  CatmullRomCurve3,
  ExtrudeGeometry,
  Float32BufferAttribute,
  PlaneGeometry,
  Shape,
  Vector3,
} from 'three';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { useShallow } from 'zustand/react/shallow';
import { fetchBuildings } from '../../utils/osm';
import { getCenterOfList } from '../../utils/geoCalculationUtils';
import { ThreeContext } from '../../context/ThreeContext';
import useUiStore, { SELECTION_MODE } from '../../stores/uiStore';

function Street({ building, showLabel }) {
  const { scene } = useContext(ThreeContext);

  const streetGeometry = useMemo(() => {
    // Create the cross-section of the street
    const shape = new Shape();
    const width = building.properties.highway === 'footway' ? 5 : 12;
    shape.moveTo(0, -width / 2);
    shape.lineTo(0, width / 2);
    shape.lineTo(1, width / 2);
    shape.lineTo(1, -width / 2);

    // Create the path of the street
    const pathPoints = building.coordinates.map((coord) => new Vector3(coord.x / 1.0, 0.05, coord.y / 1.0));
    const path = new CatmullRomCurve3(pathPoints);

    // Create the street geometry
    const geometry = new ExtrudeGeometry(shape, {
      steps: pathPoints.length,
      bevelEnabled: false,
      extrudePath: path,
      depth: 0.1,
    });

    geometry.normalizeNormals();
    geometry.computeVertexNormals();

    return geometry;
  }, [building]);

  useEffect(() => {
    if (!showLabel || !building?.properties?.name) {
      return;
    }

    const loader = new FontLoader();
    let box;
    loader.load('https://raw.githubusercontent.com/mrdoob/three.js/dev/examples/fonts/helvetiker_regular.typeface.json', (font) => {
      function splitListByXEntries(list, entries) {
        const result = [];
        for (let i = 0; i < list.length; i += entries) {
          result.push(list.slice(i, i + entries));
        }
        return result;
      }

      const splittedList = splitListByXEntries(building.coordinates, 80);
      splittedList.forEach((list) => {
        if (list.length < 2) return;
        const geometry = new TextGeometry(
          building.properties.name.replace('ß', 'ss').replace('ü', 'ue').replace('ö', 'oe').replace('ä', 'ae')
            .replace('Ü', 'Ue')
            .replace('Ö', 'Oe')
            .replace('Ä', 'Ae'),
          {
            font,
            size: 1.5,
            height: 0.2,
            depth: 1,
            curveSegments: 2,
            bevelEnabled: false,
            bevelThickness: 1,
            bevelSize: 2,
            bevelOffset: 0,
            bevelSegments: 3,
          },
        );
        const material = new THREE.MeshStandardMaterial({ color: 'black' });
        box = new THREE.Mesh(geometry, material);
        box.renderOrder = 999;
        box.material.depthTest = false;
        box.material.depthWrite = false;
        box.position.set(getCenterOfList(list).x / 1, 1, getCenterOfList(list).y / 1);
        geometry.rotateZ(-Math.PI / 2);
        geometry.rotateX(-Math.PI / 2);
        scene.add(box);
      });
    });

    return () => {
      scene.remove(box);
    };
  }, [building, showLabel]);

  const color = useMemo(() => {
    if (building.properties.highway === 'footway') {
      return '#494949';
    }
    return '#494949';
  }, [building]);

  return (
    <mesh
      geometry={streetGeometry}
      receiveShadow
      position={[0, building.properties.highway === 'footway' ? 0.1 : 0.05, 0]}
      userData={{ ignoreForShade: true }}
    >
      <meshStandardMaterial color={color} />
    </mesh>
  );
}

export default function Streets({ boundingBox }) {
  const [buildings, setBuildings] = useState([]);
  const [selectedPlace, selectionMode] = useUiStore(useShallow((state) => [state.selectedPlace, state.selectionMode]));

  useEffect(() => {
    async function fetchData() {
      const osmStreets = await fetchBuildings(boundingBox, `
        [out:json];
        (
          way["highway"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
        );
        out body;
        >;
        out skel qt;
      `, 'LineString');
      setBuildings(osmStreets);
    }
    fetchData();
  }, [boundingBox]);

  const renderBuildings = useCallback(() => {
    const dict = {};
    return buildings.map((building, index) => {
      const showLabel = selectionMode === SELECTION_MODE.BUILDING && building.properties.name && (!dict[building.properties.name] || dict[building.properties.name] < 9);
      dict[building.properties.name] = !dict[building.properties.name] ? 1 : dict[building.properties.name] + 1;
      return (
        <Street key={building.id} building={building} showLabel={showLabel} />
      );
    });
  }, [buildings, selectionMode]);

  return (
    <>
      {renderBuildings()}
    </>
  );
}
