// src/hooks/useSelectable.js
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import SunCalc from 'suncalc';
import { useShallow } from 'zustand/react/shallow';
import dayjs from 'dayjs';
import useScenarioStore from '../stores/scenarioStore';
import { ThreeContext } from '../context/ThreeContext';
import {
  getAllUuidWithChildren,
  getRelevantObjectsForRaycasting,
} from '../utils/sceneUtils';
import { getInShade, getSunPosition } from '../utils/geoCalculationUtils';
import useUiStore from '../stores/uiStore';

export const SHADE_CALCULATION_RANGES = {
  ALL_YEAR: {
    label: 'All Year', value: 'all year', monthStart: 1, monthEnd: 12,
  },
  SUMMER_HALF: {
    label: 'Summer Half', value: 'summer half', monthStart: 4, monthEnd: 9,
  },
  SUMMER_ONLY: {
    label: 'Summer Only', value: 'summer only', monthStart: 6, monthEnd: 8,
  },
  JULY: {
    label: 'July', value: 'july', monthStart: 7, monthEnd: 7,
  },
};

export default function useShadeCalculation() {
  const [selectedPlace] = useUiStore(useShallow((state) => [state.selectedPlace]));
  const { scene } = useContext(ThreeContext);
  const { selectedDateTime } = useScenarioStore();

  const calculateShade = (entity, datesConfig = [{ date: selectedDateTime }]) => {
    const mesh = scene.getObjectByName(`entity_${entity.id}`);
    if (!mesh?.position) {
      return -1;
    }

    const boundingBox = new THREE.Box3().setFromObject(mesh);

    // Calculate the positions of the four testPoints
    const yCenter = (boundingBox.min.y + boundingBox.max.y) / 2;
    const distanceX = boundingBox.max.x - boundingBox.min.x;
    const distanceZ = boundingBox.max.z - boundingBox.min.z;

    let testPoints;
    if (distanceX < 1 && distanceZ < 1) {
      testPoints = [
        new THREE.Vector3(
          (boundingBox.min.x + boundingBox.max.x) / 2,
          yCenter,
          (boundingBox.min.z + boundingBox.max.z) / 2,
        ),
      ];
    } else {
      testPoints = [
        new THREE.Vector3(boundingBox.min.x, yCenter, boundingBox.min.z),
        new THREE.Vector3(boundingBox.min.x, yCenter, boundingBox.max.z),
        new THREE.Vector3(boundingBox.max.x, yCenter, boundingBox.min.z),
        new THREE.Vector3(boundingBox.max.x, yCenter, boundingBox.max.z),
        new THREE.Vector3(
          (boundingBox.min.x + boundingBox.max.x) / 2,
          yCenter,
          (boundingBox.min.z + boundingBox.max.z) / 2,
        ),
      ];
    }
    const ignoreEntityUuids = entity.attachedEntityId ? [scene.getObjectByName(`entity_${entity.attachedEntityId}`).uuid] : [];
    const relevantObjects = getRelevantObjectsForRaycasting(mesh, scene, [...getAllUuidWithChildren(mesh), ...ignoreEntityUuids]);

    const measures = [];
    let measureCounterTotal = 0;
    datesConfig.forEach((dateConfig) => {
      const month = dayjs(dateConfig.date).month();
      const day = dayjs(dateConfig.date).date();
      const times = SunCalc.getTimes(new Date(2023, month, day), selectedPlace.lat, selectedPlace.lon);
      const hoursRange = Math.floor(times.sunsetStart.getHours() - times.sunriseEnd.getHours());
      for (let hour = times.solarNoon.getHours() - hoursRange / 2; hour < times.solarNoon.getHours() + hoursRange / 2; hour++) {
        const date = new Date(2023, month + 1, day, hour);
        const sunPosition = getSunPosition(date.getTime(), selectedPlace.lat, selectedPlace.lon);
        // console.log('date', date);
        // console.log('sunPosition', sunPosition);
        const intensity = 1.0 - (Math.abs(times.solarNoon.getHours() - hour) / 18.0);
        let shadeCount = 0;
        let measuresCounter = 0;
        for (const corner of testPoints) {
          const inShade = getInShade(corner, sunPosition, relevantObjects);
          if (inShade.length > 0) {
            shadeCount += 1;
          } else {
            // debugger;
          }
          measuresCounter++;
          measureCounterTotal++;
        }
        const average = shadeCount / measuresCounter;
        if (!isNaN(average)) {
          measures.push({
            day, month, hour, shade: isNaN(average) ? null : average, intensity,
          });
        }
      }
    });

    const totalShade = measures.length > 0 ? measures.reduce((acc, curr) => acc + curr.shade, 0) / measures.length : null;

    // console.log(datesConfig);
    return {
      totalShade, measures,
    };
  };

  return { calculateShade };
}
