import * as THREE from 'three';

export const createShape = (building, forcedHeight = null) => {
  const shape = new THREE.Shape();
  const [firstCoord, ...coords] = building.coordinatesNormalized;
  shape.moveTo(firstCoord.x, -firstCoord.y);
  coords.forEach((coord) => shape.lineTo(coord.x, -coord.y));
  shape.lineTo(firstCoord.x, -firstCoord.y);

  const extrudeSettings = {
    steps: 1,
    depth: forcedHeight ?? building.height,
    bevelEnabled: false,
  };

  const newGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
  newGeometry.rotateX(-Math.PI / 2);
  newGeometry.normalizeNormals();
  newGeometry.computeVertexNormals();
  return newGeometry;
};
