import React, { useRef } from 'react';
import useScenarioStore from '../../stores/scenarioStore';

export default function Bench({
  position, width = 0.5, length = 0.5, height = 1.1, colorLegs, colorSeat,
}) {
  const meshRef = useRef();
  const { setSelectedObject, setPreviewData } = useScenarioStore();

  const legPosition = width / 2 - 0.05;
  const legLength = length / 2 - 0.05;
  const backrestHeight = 0.5;
  const backrestThickness = 0.02;
  const armrestHeight = 0.3;
  const armrestThickness = 0.04;

  return (
    <group
      position={position}
      onClick={() => {
        setSelectedObject(meshRef.current);
        setPreviewData({
          type: 'Bench',
          data: {
            position,
          },
        });
      }}
    >
      <mesh ref={meshRef} position={[0, height / 2, 0]} name="Bench seat" castShadow receiveShadow>
        <boxGeometry args={[width, 0.02, length]} />
        <meshStandardMaterial color={colorSeat} />
      </mesh>

      {[
        [-legPosition, height / 4, -legLength],
        [legPosition, height / 4, -legLength],
        [-legPosition, height / 4, legLength],
        [legPosition, height / 4, legLength],
      ].map((pos, i) => (
        <mesh key={i} position={pos} name="Bench legs" castShadow receiveShadow>
          <boxGeometry args={[0.04, height / 2, 0.04]} />
          <meshStandardMaterial color={colorLegs} />
        </mesh>
      ))}
      <mesh position={[0, height / 2.0 + backrestHeight / 2.0, -length / 2 + backrestThickness / 2]} name="Bench backrest" castShadow receiveShadow>
        <boxGeometry args={[width, backrestHeight, backrestThickness]} />
        <meshStandardMaterial color={colorSeat} />
      </mesh>

      {[
        [-legPosition, height / 2 + armrestHeight / 2, 0],
        [legPosition, height / 2 + armrestHeight / 2, 0],
      ].map((pos, i) => (
        <mesh key={i} position={pos} name="Bench armrests" castShadow receiveShadow>
          <boxGeometry args={[armrestThickness, armrestHeight, length]} />
          <meshStandardMaterial color={colorSeat} />
        </mesh>
      ))}
    </group>
  );
}
