import osmtogeojson from 'osmtogeojson';
import { getCenter, getMedian, mapLatLonCoordinates } from './geoCalculationUtils';

const extractCoordinates = (feature) => {
  switch (feature.geometry.type) {
    case 'Polygon':
      return feature.geometry.coordinates[0];
    case 'Point':
      return [feature.geometry.coordinates];
    case 'LineString':
      return feature.geometry.coordinates;
    default:
      return [];
  }
};

const getHeight = (properties) => {
  const fields = ['height', 'building:height', 'building:levels'];
  for (const field of fields) {
    if (properties[field] && isNaN(properties[field]) === false) {
      if (field === 'building:levels') {
        return properties[field] * 3;
      }
      return properties[field];
    }
  }
  return null;
};

const getCenterOfCoordinates = (coordinates) => {
  const result = coordinates.reduce(
    (sum, coord) => ({ x: sum.x + coord.x, y: sum.y + coord.y }),
    { x: 0, y: 0 },
  );
  return { x: result.x / coordinates.length, y: result.y / coordinates.length };
};

const createBuilding = (feature, boundingBox) => {
  const extractedCoordinates = extractCoordinates(feature);
  const coordinates = mapLatLonCoordinates(getCenter(boundingBox), extractedCoordinates);
  const centerNormalized = getCenterOfCoordinates(coordinates);
  const positionNormalized = [centerNormalized.x, 0, centerNormalized.y];
  const coordinatesNormalized = coordinates.map((coord) => ({ x: coord.x - centerNormalized.x, y: coord.y - centerNormalized.y }));

  return ({
    id: feature.id,
    type: feature.geometry.type,
    properties: feature.properties,
    height: getHeight(feature.properties),
    coordinates,
    geoCoordinates: extractedCoordinates,
    coordinatesNormalized,
    centerNormalized,
    positionNormalized,
    // center: getCenterOfList(coordinates),
  });
};

export const fetchBuildings = async (boundingBox, query, typeFilter = 'Polygon') => {
  const overpassUrl = new URL('http://overpass-api.de/api/interpreter');

  overpassUrl.searchParams.set('data', query);

  const response = await fetch(overpassUrl);
  const data = await response.json();
  const geojson = osmtogeojson(data);

  const osmFeatures = geojson.features.filter((feature) => !typeFilter || feature.geometry?.type === typeFilter).map((feature) => createBuilding(feature, boundingBox));
  const medianHeight = Math.max(4.5, getMedian(osmFeatures.filter((building) => building.height != null).map((building) => building.height)) || 0);
  return osmFeatures.map((building) => ({ ...building, height: building.height || medianHeight }));
};

export const getNearestBuilding = (osmBuildings, center) => osmBuildings.reduce((nearest, building) => {
  const buildingCenter = building.geoCoordinates.reduce(
    (sum, coord) => ({ lat: sum.lat + coord[1], lon: sum.lon + coord[0] }),
    { lat: 0, lon: 0 },
  );
  buildingCenter.lat /= building.geoCoordinates.length;
  buildingCenter.lon /= building.geoCoordinates.length;

  const distance = Math.hypot(buildingCenter.lat - center.lat, buildingCenter.lon - center.lon);
  return distance < nearest.distance ? { building, distance } : nearest;
}, { building: null, distance: Infinity });
