import { CameraControls, OrbitControls } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import React, {
  memo,
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Vector3 } from 'three';
import { useShallow } from 'zustand/react/shallow';
import { Box } from '@mui/material';
import Wall from '../components/3d/Wall';
import Table from '../components/3d/Table';
import { ThreeContext } from '../context/ThreeContext';
import Draggable from '../components/3d/Draggable';
import useScenarioStore from '../stores/scenarioStore';
import Sunlights from '../components/3d/Sunlights';
import Sky from '../components/3d/Sky';
import Sourrounding from './3d/Sourrounding';
import Balcony from './3d/Balcony';
import useUiStore from '../stores/uiStore';
import useSelectionModeClick from '../hooks/useSelectionModeClick';
import UiHelper from './UiHelper';
import CameraControl from './CameraControl';
import useViewStore from '../stores/viewStore';
import ExternSky from '../components/3d/ExternSky';
import Sidebar from './sidebar/Sidebar';
import { ThreeProvider } from '../context/ThreeProvider';
import ShadowVisualizer from '../components/3d/ShadowVisualizer';

export default function Scenario() {
  const { camera, scene } = useContext(ThreeContext);

  const [selectedPlace] = useUiStore(useShallow((state) => [state.selectedPlace]));

  const [boundingBox, setBoundingBox] = useState(
    selectedPlace ? selectedPlace.boundingBox : {
      min: { lon: 13.40279740093464, lat: 52.52075191523308 },
      max: { lon: 13.412137037513347, lat: 52.52754505600548 },
    },
  );

  const center = useMemo(() => (boundingBox ? {
    lat: (boundingBox.min.lat + boundingBox.max.lat) / 2,
    lon: (boundingBox.min.lon + boundingBox.max.lon) / 2,
  } : { lat: 0, lon: 0 }), [boundingBox]);

  useEffect(() => {
    if (selectedPlace) {
      setBoundingBox(selectedPlace.boundingBox);
    }
  }, [selectedPlace]);
  return (
    <Canvas shadows key="canvas" camera={camera} scene={scene}>
      <UiHelper />
      <CameraControl camera={camera} scene={scene} />
      <ExternSky latitude={center.lat} longitude={center.lon} />
      <Sunlights latitude={center.lat} longitude={center.lon} />
      {boundingBox && (<Sourrounding boundingBox={boundingBox} />)}
      <Balcony />
    </Canvas>
  );
}
