export const SUN_ATTRIBUTE = {
  FULL_SUN: { label: 'Full sun' },
  MAINLY_SUN: { label: 'Mainly sun' },
  PARTIAL_SUN: { label: 'Partial sun' },
  MAINLY_SHADE: { label: 'Mainly shade' },
  SHADE: { label: 'Shade' },
};

export const getSunFactor = (shadeValue) => {
  if (shadeValue > 0.80) return SUN_ATTRIBUTE.FULL_SUN;
  if (shadeValue > 0.60) return SUN_ATTRIBUTE.MAINLY_SUN;
  if (shadeValue > 0.40) return SUN_ATTRIBUTE.PARTIAL_SUN;
  if (shadeValue > 0.20) return SUN_ATTRIBUTE.MAINLY_SHADE;
  return SUN_ATTRIBUTE.SHADE;
};

export const SPACE_ATTRIBUTE = {
  BIG: { label: 'Big' },
  MEDIUM: { label: 'Medium' },
  SMALL: { label: 'Small' },
};

export const WATER_ATTRIBUTE = {
  MUCH: { label: 'Much' },
  MEDIUM: { label: 'Medium' },
  FEW: { label: 'Few' },
};
