import React from 'react';
import { Shape } from 'three';

function PlantPotSingle({
  outerRadius = 0.1,
  innerRadius = 0.09,
  height = 0.1,
  baseHeight = 0.05,
  soilHeight = 0.08,
}) {
  const shape = new Shape();

  shape.moveTo(outerRadius, 0);
  shape.absarc(0, 0, outerRadius, 0, Math.PI * 2, false);

  const holePath = new Shape();
  holePath.moveTo(innerRadius, 0);
  holePath.absarc(0, 0, innerRadius, 0, Math.PI * 2, true);
  shape.holes.push(holePath);

  const extrudeSettings = {
    depth: height,
    bevelEnabled: false,
    curveSegments: 64,
  };

  return (
    <group>
      <mesh
        position={[0, height / 2, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        castShadow
        receiveShadow
      >
        <extrudeGeometry args={[shape, extrudeSettings]} />
        <meshStandardMaterial color="#78866B" />
      </mesh>

      <mesh
        position={[0, baseHeight / 2, 0]}
        castShadow
        receiveShadow
      >
        <cylinderGeometry args={[outerRadius, outerRadius, baseHeight, 64]} />
        <meshStandardMaterial color="#78866B" />
      </mesh>

      <mesh
        position={[0, baseHeight + soilHeight / 2, 0]}
        castShadow
        receiveShadow
      >
        <cylinderGeometry args={[innerRadius, innerRadius, soilHeight, 64]} />
        <meshStandardMaterial color="#66410b" />
      </mesh>
    </group>
  );
}

export default PlantPotSingle;
