import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Button, Card, CardContent, Divider, Grid, Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import useScenarioStore from '../../../stores/scenarioStore';

export default function SourroundingHeightChanger({ osmElement }) {
  const { setSourroundingModification, sourroundingModification } = useScenarioStore();

  const height = useMemo(() => sourroundingModification[osmElement.id]?.height || osmElement.height, [sourroundingModification, osmElement]);

  const setHeightValue = (value) => {
    setSourroundingModification(osmElement.id, 'height', value);
  };

  const handleIncrement = () => {
    setHeightValue(height + 0.5);
  };

  const handleDecrement = () => {
    if (height > 0) {
      setHeightValue(height - 0.5);
    }
  };

  const handleInputChange = (event) => {
    setHeightValue(Number(event.target.value));
  };

  return (
    <Box justifyContent="center" display="flex">
      <Button onClick={handleDecrement}>-</Button>
      <TextField
        type="number"
        value={height}
        onChange={handleInputChange}
        inputProps={{ min: 0 }}
        label="Building height (m)"
      />
      <Button onClick={handleIncrement}>+</Button>
    </Box>
  );
}
