import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button, Card,
  Checkbox,
  Divider, FormControl,
  FormControlLabel,
  Grid, IconButton, InputLabel,
  MenuItem,
  Select,
  Slider,
  Typography,
} from '@mui/material';
import {
  Abc, BorderAll,
  BorderBottom,
  BorderLeft, BorderOuter,
  BorderRight,
  BorderTop,
  Edit,
  EditCalendar,
  EditNote,
  ExpandMore, VerticalAlignTop,
} from '@mui/icons-material';
import useBalconyStore, { BALCONY_WALL_TYPE } from '../../stores/balconyStore';
import SliderWithButtons from '../../components/SliderWithButtons';
import EditableCardSection from '../EditableCardSection';
import KeyValueLabel from '../../components/KeyValueLabel';

function WallSettings({
  wall, setWall, label, setForAll, LabelIcon,
}) {
  return (
    <EditableCardSection
      label={`${label} wall`}
      LabelIcon={LabelIcon}
      renderChildrenFunction={(editable) => (
        <Grid container rowSpacing={1}>
          {editable ? (
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={wall.enabled || false}
                    onChange={(event) => setWall({ ...wall, enabled: event.target.checked })}
                  />
                )}
                label="Enabled"
              />
            </Grid>
          ) : !wall.enabled && (
            <Grid item xs={6}>
              <KeyValueLabel label="Enabled" value={wall.enabled ? 'Yes' : 'No'} />
            </Grid>
          )}
          {(wall.enabled || editable) && (
          <>
            {editable ? (
              <Grid item xs={12}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={wall.toCeil || false}
                      onChange={(event) => setWall({ ...wall, toCeil: event.target.checked })}
                    />
                      )}
                  label="To ceil"
                />
              </Grid>
            ) : wall.toCeil && (
            <Grid item xs={6}>
              <KeyValueLabel label="To ceil" value="Yes" />
            </Grid>
            )}
            <Grid item xs={editable ? 12 : 6}>
              <SliderWithButtons
                value={wall.height}
                setValue={(value) => setWall({ ...wall, height: value })}
                min={0.1}
                max={4}
                label="Height"
                step={0.01}
                disabled={!wall.enabled || wall.toCeil}
                unitLabel="m"
                readOnly={!editable}
              />
            </Grid>
            <Grid item xs={6}>
              {editable ? (
                <FormControl fullWidth>
                  <InputLabel id="select-label">Type</InputLabel>
                  <Select
                    value={wall.type}
                    onChange={(value) => {
                      setWall({ ...wall, type: value.target.value });
                    }}
                    disabled={!wall.enabled}
                    labelId="select-label"
                  >
                    {Object.keys(BALCONY_WALL_TYPE).map((key) => (
                      <MenuItem key={key} value={BALCONY_WALL_TYPE[key].name}>
                        {BALCONY_WALL_TYPE[key].label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (<KeyValueLabel label="Type" value={BALCONY_WALL_TYPE[wall.type].label} />)}
            </Grid>
            {setForAll && editable && (
            <Grid item xs={12}>
              <Button
                onClick={() => setForAll(wall)}
                disabled={!wall.enabled}
              >
                Set for all walls
              </Button>
            </Grid>
            )}
          </>
          )}
        </Grid>
      )}
    />
  );
}

function TopWallSettings({
  wall, setWall, LabelIcon,
}) {
  return (
    <EditableCardSection
      label="Ceil"
      LabelIcon={LabelIcon}
      renderChildrenFunction={(editable) => (
        <Grid container rowSpacing={2}>
          {editable ? (
            <Grid item xs={12}>
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={wall.enabled || false}
                    onChange={(event) => setWall({ ...wall, enabled: event.target.checked })}
                  />
                        )}
                label="Enabled"
              />
            </Grid>
          ) : !wall.enabled && (
            <Grid item xs={6}>
              <KeyValueLabel label="Enabled" value={wall.enabled ? 'Yes' : 'No'} />
            </Grid>
          )}
          {(wall.enabled || editable) && (
            <>
              {editable ? (
                <Grid item xs={12}>
                  <SliderWithButtons
                    value={wall.offsetY}
                    setValue={(value) => setWall({ ...wall, offsetY: value })}
                    min={0.5}
                    max={4}
                    label="Offset height"
                    step={0.01}
                    disabled={!wall.enabled}
                  />
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <KeyValueLabel label="Offset height" value={`${wall.offsetY} m`} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container rowSpacing={1}>
                  {editable ? (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            disabled={!wall.enabled}
                            checked={wall.sameSizeAsFloor || false}
                            onChange={(event) => setWall({ ...wall, sameSizeAsFloor: event.target.checked })}
                          />
                    )}
                        label="Same size as floor"
                      />
                    </Grid>
                  ) : (wall.sameSizeAsFloor && (
                  <Grid item xs={6}>
                    <KeyValueLabel label="Same size as floor" value="Yes" />
                  </Grid>
                  ))}
                  <Grid item xs={editable ? 12 : 6}>
                    <SliderWithButtons
                      value={wall.sizeX}
                      setValue={(value) => setWall({ ...wall, sizeX: value })}
                      min={0.1}
                      max={20}
                      label="Width"
                      step={0.01}
                      disabled={!wall.enabled || wall.sameSizeAsFloor}
                      readOnly={!editable}
                    />
                  </Grid>
                  <Grid item xs={editable ? 12 : 6}>
                    <SliderWithButtons
                      value={wall.sizeZ}
                      setValue={(value) => setWall({ ...wall, sizeZ: value })}
                      min={0.1}
                      max={20}
                      label="Length"
                      step={0.01}
                      disabled={!wall.enabled || wall.sameSizeAsFloor}
                      readOnly={!editable}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {editable ? (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      value={wall.type}
                      onChange={(value) => {
                        setWall({ ...wall, type: value.target.value });
                      }}
                      disabled={!wall.enabled}
                      labelId="demo-simple-select-label"
                    >
                      {Object.keys(BALCONY_WALL_TYPE).map((key) => (
                        <MenuItem key={key} value={BALCONY_WALL_TYPE[key].name}>
                          {BALCONY_WALL_TYPE[key].label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <KeyValueLabel label="Type" value={BALCONY_WALL_TYPE[wall.type].label} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      )}
    />
  );
}

export default function BalconyDetailsContent() {
  const {
    setSizeX, setSizeZ, sizeX, sizeZ, setSideWallLeft, setSideWallRight,
    setSideWallFront,
    sideWallLeft, sideWallRight, sideWallFront, sideWallTop, setSideWallTop,
    sideWallBack, setSideWallBack,
  } = useBalconyStore();

  const setForAllWalls = (value) => {
    setSideWallLeft(value);
    setSideWallRight(value);
    setSideWallFront(value);
  };

  return (
    <Grid container rowSpacing={1}>
      <Grid item xs={12}>
        <EditableCardSection
          label="Floor size"
          LabelIcon={BorderOuter}
          renderChildrenFunction={(editable) => (
            <>
              <Grid item xs={editable ? 12 : 6}>
                <SliderWithButtons
                  value={sizeX}
                  setValue={(value) => setSizeX(value)}
                  min={0.2}
                  max={15}
                  label="Width"
                  step={0.01}
                  readOnly={!editable}
                  unitLabel="m"
                />
              </Grid>
              <Grid item xs={editable ? 12 : 6}>
                <SliderWithButtons
                  value={sizeZ}
                  setValue={(value) => setSizeZ(value)}
                  min={0.2}
                  max={15}
                  label="Length"
                  step={0.01}
                  readOnly={!editable}
                  unitLabel="m"
                />
              </Grid>
            </>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <WallSettings label="Left" wall={sideWallLeft} setWall={setSideWallLeft} setForAll={setForAllWalls} LabelIcon={BorderLeft} />
      </Grid>
      <Grid item xs={12}>
        <WallSettings label="Right" wall={sideWallRight} setWall={setSideWallRight} setForAll={setForAllWalls} LabelIcon={BorderRight} />
      </Grid>
      <Grid item xs={12}>
        <WallSettings label="Front" wall={sideWallFront} setWall={setSideWallFront} setForAll={setForAllWalls} LabelIcon={BorderTop} />
      </Grid>
      <Grid item xs={12}>
        <WallSettings label="Back" wall={sideWallBack} setWall={setSideWallBack} setForAll={setForAllWalls} LabelIcon={BorderBottom} />
      </Grid>
      <Grid item xs={12}>
        <TopWallSettings wall={sideWallTop} setWall={setSideWallTop} LabelIcon={VerticalAlignTop} />
      </Grid>
    </Grid>
  );
}
