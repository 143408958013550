import { useEffect, useState } from 'react';
import { ENTITY_PLACE_TYPE as PLACE_TYPE } from '../stores/entitiesStore';
import useBalconyStore from '../stores/balconyStore';

const usePositionAtBalcony = () => {
  const [sizeX, sizeZ, sideWallLeft, sideWallRight, sideWallFront, sideWallBack] = useBalconyStore((state) => [
    state.sizeX,
    state.sizeZ,
    state.sideWallLeft,
    state.sideWallRight,
    state.sideWallFront,
    state.sideWallBack,
  ]);

  const getSideWallLength = () => {
    const leftWallEnabled = sideWallLeft.enabled && sideWallLeft.height < 2;
    const rightWallEnabled = sideWallRight.enabled && sideWallRight.height < 2;
    const frontWallEnabled = sideWallFront.enabled && sideWallFront.height < 2;
    const backWallEnabled = sideWallBack.enabled && sideWallBack.height < 2;

    let enabledWallsLength = 0;
    if (rightWallEnabled) enabledWallsLength += sizeZ;
    if (frontWallEnabled) enabledWallsLength += sizeX;
    if (leftWallEnabled) enabledWallsLength += sizeZ;
    if (backWallEnabled) enabledWallsLength += sizeX;
    return enabledWallsLength;
  };

  return {
    getSideWallLength,
    getEntityPosition: (positionX, positionZ, placeType, width, length) => {
      if (placeType === PLACE_TYPE.FLOOR.name) {
        return [((positionX / 100.0) * (sizeX - width)) - (sizeX - width) / 2.0, 0, -(positionZ / 100.0) * (sizeZ - length) - length / 2];
      }

      if (placeType === PLACE_TYPE.SIDEWALL.name) {
        const leftWallEnabled = sideWallLeft.enabled && sideWallLeft.height < 2;
        const rightWallEnabled = sideWallRight.enabled && sideWallRight.height < 2;
        const frontWallEnabled = sideWallFront.enabled && sideWallFront.height < 2;
        const backWallEnabled = sideWallBack.enabled && sideWallBack.height < 2;
        const relativePosition = positionX / 100.0;

        const enabledWallsLength = getSideWallLength();

        const pointOnEnabledWall = relativePosition * enabledWallsLength;

        let x; let y; let
          z;

        if (rightWallEnabled && pointOnEnabledWall <= sizeZ) {
          const relativePositionRightWall = pointOnEnabledWall / sizeZ;
          y = sideWallRight.height;
          x = -sizeX / 2;
          z = -relativePositionRightWall * sizeZ;
        } else if (frontWallEnabled && pointOnEnabledWall <= (rightWallEnabled ? sizeZ : 0) + sizeX) {
          const effectiveFrontStart = rightWallEnabled ? sizeZ : 0;
          const relativePositionFrontWall = (pointOnEnabledWall - effectiveFrontStart) / sizeX;
          y = sideWallFront.height;
          x = relativePositionFrontWall * sizeX - sizeX / 2;
          z = -sizeZ;
        } else if (leftWallEnabled && pointOnEnabledWall <= (rightWallEnabled ? sizeZ : 0) + (frontWallEnabled ? sizeX : 0) + sizeZ) {
          const effectiveLeftStart = (rightWallEnabled ? sizeZ : 0) + (frontWallEnabled ? sizeX : 0);
          const relativePositionLeftWall = (pointOnEnabledWall - effectiveLeftStart) / sizeZ;
          y = sideWallLeft.height;
          x = sizeX / 2;
          z = -(1 - relativePositionLeftWall) * sizeZ;
        } else if (backWallEnabled) {
          const effectiveBackStart = (rightWallEnabled ? sizeZ : 0) + (frontWallEnabled ? sizeX : 0) + (leftWallEnabled ? sizeZ : 0);
          const relativePositionBackWall = (pointOnEnabledWall - effectiveBackStart) / sizeX;
          y = sideWallBack.height;
          x = (1 - relativePositionBackWall) * sizeX - sizeX / 2;
          z = 0;
        }

        return [x, y, z];
      }
      throw new Error(`Unknown place type: ${placeType}`);
    },
  };
};

export default usePositionAtBalcony;
