import * as THREE from 'three';

export const getRelevantObjectsForRaycasting = (referenceObj, scene, blacklist, nearObjects = []) => {
  scene.traverse((child) => {
    if (child.uuid === blacklist[blacklist.length - 1]) {
      // debugger; // PTORBLEM BLACKLIST CONTAINS ID NOT UUID
    }
    if (!child.isMesh || blacklist.includes(child.uuid) || child.name === 'sky' || child.userData.ignoreForShade === true) {
      return;
    }

    const bbox = new THREE.Box3().setFromObject(child);
    const sizeY = bbox.max.y - bbox.min.y;
    const sizeX = bbox.max.x - bbox.min.x;
    const sizeZ = bbox.max.z - bbox.min.z;
    const centerPos = new THREE.Vector3().addVectors(bbox.min, bbox.max).multiplyScalar(0.5);
    const distance = referenceObj.position.distanceTo(centerPos);
    const threshold = Math.max(30, Math.abs(referenceObj.position.y - centerPos.y) + sizeY * 2 + sizeX * 0.5 + sizeZ * 0.5);
    if (distance < threshold * 2.0) {
      // console.log('IN');
      // console.log(child.name, distance, threshold);
      nearObjects.push(child);
    } else {
      // console.log('OUT');
    }
    // If the child has its own children, recursively check them
    if (child.children.length > 0) {
      getRelevantObjectsForRaycasting(child, referenceObj, nearObjects);
    }
  });
  return nearObjects;
};

export const getAllUuidWithChildren = (obj, uuids = []) => {
  uuids.push(obj.uuid);
  obj.children.forEach((child) => {
    getAllUuidWithChildren(child, uuids);
  });
  return uuids;
};

export const getAllWithTag = (scene, tag, objects = []) => {
  scene.traverse((object) => {
    if (object.userData.tag === tag) {
      objects.push(object);
    }
    if (object.children.length > 0) {
      for (const child of object.children) {
        getAllWithTag(child, tag, objects);
      }
    }
  });
  return objects;
};
