import SunCalc from 'suncalc';
import * as THREE from 'three';
import proj4 from 'proj4';

export const getSunPosition = (date, latitude, longitude, radius = 100_000) => {
  const position = SunCalc.getPosition(date, latitude, longitude);

  const x = radius * (Math.cos(position.altitude)) * (Math.cos(position.azimuth));
  const z = radius * (Math.cos(position.altitude)) * (Math.sin(position.azimuth));
  const y = radius * (Math.sin(position.altitude));
  return [-x, y, -z];
};

export const getInShade = (originPosition, sunPosition, children) => {
  const sunPositionVector = new THREE.Vector3(...sunPosition);
  const direction = new THREE.Vector3().subVectors(sunPositionVector, originPosition).normalize();

  const raycaster = new THREE.Raycaster(originPosition, direction);
  const intersects = raycaster.intersectObjects(children, true).filter((intersect) => intersect.object.name !== 'sky');

  return intersects;
};

export const getCenter = (boundingBox) => ({
  lat: (boundingBox.min.lat + boundingBox.max.lat) / 2,
  lon: (boundingBox.min.lon + boundingBox.max.lon) / 2,
});

export const getCenterOfList = (xYCoordinateList) => {
  const sum = xYCoordinateList.reduce((acc, curr) => {
    acc.x += curr.x;
    acc.y += curr.y;
    return acc;
  }, { x: 0, y: 0 });

  return {
    x: sum.x / xYCoordinateList.length,
    y: sum.y / xYCoordinateList.length,
  };
};

export const mapLatLonCoordinates = (center, coordinates) => {
  const utmZone = Math.floor((center.lon + 180) / 6) + 1;
  const utmProjection = `+proj=utm +zone=${utmZone} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`;

  const centerUTM = proj4('EPSG:4326', utmProjection, [center.lon, center.lat]);

  return coordinates.map((coord) => {
    const utmCoord = proj4('EPSG:4326', utmProjection, coord);
    return {
      x: (utmCoord[1] - centerUTM[1]),
      y: utmCoord[0] - centerUTM[0],
    };
  });
};

export function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}

export function radiansToDegrees(radians) {
  return radians * (180 / Math.PI);
}

export const getBoundingBox = (lat, lon, distance) => {
  const radius = 6371; // radius of the Earth in kilometers
  const delta = distance / radius; // angular distance in radians

  const minLat = lat - radiansToDegrees(delta);
  const maxLat = lat + radiansToDegrees(delta);

  const deltaLon = radiansToDegrees(Math.asin(Math.sin(delta) / Math.cos(degreesToRadians(lat))));
  let minLon = lon - deltaLon;
  let maxLon = lon + deltaLon;

  // Ensure longitude stays within the range [-180, 180]
  if (minLon < -180) minLon += 360;
  if (maxLon > 180) maxLon -= 360;

  return {
    min: { lat: minLat, lon: minLon },
    max: { lat: maxLat, lon: maxLon },
  };
};

export const getMedian = (values) => {
  if (values.length === 0) return 0;
  values.sort((a, b) => a - b);
  const half = Math.floor(values.length / 2);
  return values.length % 2 === 0 ? (values[half - 1] + values[half]) / 2.0 : values[half];
};
