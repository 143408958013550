import React, { useEffect, useState } from 'react';
import {
  Box, Button, Card, CardContent, Divider, Grid, Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeIcon from '@mui/icons-material/Home';
import { ArrowForward, Save } from '@mui/icons-material';
import { useShallow } from 'zustand/react/shallow';
import CustomDateTimePicker from '../DateTimePicker';
import AddressAutocomplete from '../AddressAutocomplete';
import useUiStore, { SELECTION_MODE } from '../../stores/uiStore';
import useScenarioStore from '../../stores/scenarioStore';
import SourroundingHeightChanger from './fragments/SourroundingHeightChanger';
import BalconyRotationSlider from '../BalconyRotationSlider';
import BalconyPositionSlider from '../BalconyPositionSlider';
import useViewStore from '../../stores/viewStore';
import useBalconyStore, { BALCONY_TYPE } from '../../stores/balconyStore';

export default function BalconyPositionSelectionModeContent() {
  const {
    selectedBuilding, temporarySelectedBuilding, setSelectedBuilding, setTemporarySelectedBuilding,
  } = useScenarioStore();
  const { selectionMode, setSelectionMode } = useUiStore();
  const [setFocusOnId] = useViewStore(useShallow((state) => [state.setFocusOnId]));
  const [balconyType, setBalconyType] = useBalconyStore(useShallow((state) => [state.balconyType, state.setBalconyType]));

  const [temporaryBalconyType, setTemporaryBalconyType] = useState(balconyType);

  useEffect(() => {
    if (!temporarySelectedBuilding) {
      setTemporarySelectedBuilding(selectedBuilding);
    }
  }, []);

  const handleApply = () => {
    if (balconyType !== temporaryBalconyType) {
      setBalconyType(temporaryBalconyType);
      if (temporaryBalconyType === BALCONY_TYPE.GARDEN) {
        // TODO
      } else {
        // TODO
      }
    }

    setSelectionMode(null);
    setFocusOnId(null);
    setTemporarySelectedBuilding(null);
  };

  return (
    <Grid container spacing={2} paddingX={1}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2} paddingX={1}>
              <Grid item xs={12}>
                <Typography variant="h6">3. Position</Typography>
              </Grid>
              <Grid item xs={12}>
                <BalconyRotationSlider />
              </Grid>
              <Grid item xs={12}>
                <BalconyPositionSlider balconyType={temporaryBalconyType} setBalconyType={setTemporaryBalconyType} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} paddingX={1}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between" my={3}>
              <Button
                startIcon={<ArrowBackIcon />}
                variant="outlined"
                color="error"
                onClick={() => {
                  setSelectionMode(SELECTION_MODE.BUILDING); setFocusOnId(selectedBuilding.id);
                }}
              >
                Go back
              </Button>
              <Button
                startIcon={<Save />}
                variant="contained"
                color="primary"
                onClick={handleApply}
              >
                Apply
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
