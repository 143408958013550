import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Accordion,
  AccordionSummary,
  Typography, AccordionDetails,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { ExpandMore } from '@mui/icons-material';
import { SketchPicker } from 'react-color';
import useEntitiesStore, {
  ENTITY_PLACE_TYPE,
  ENTITY_TYPE,
} from '../../../stores/entitiesStore';
import SliderWithButtons from '../../../components/SliderWithButtons';
import usePlantsStore from '../../../stores/plantsStore';
import ColorPicker from '../../../components/ColorPicker';
import useBalconyStore from '../../../stores/balconyStore';
import EditableCardSection from '../../EditableCardSection';
import KeyValueLabel from '../../../components/KeyValueLabel';
import useViewStore from '../../../stores/viewStore';
import ShadeCard from '../../ShadeGrade';

function Entity({
  entity = null, editable, setEditable, renderAdditionalContentFunc = null,
}) {
  const [removeEntity, updateEntityPartly] = useEntitiesStore(useShallow((state) => [state.removeEntity, state.updateEntityPartly]));
  const [handleEntityWillBeRemoved] = usePlantsStore(useShallow((state) => [state.handleEntityWillBeRemoved]));
  const {
    sizeX, sizeZ,
  } = useBalconyStore();
  const [setFocusOnId] = useViewStore(useShallow((state) => [state.setFocusOnId]));
  const [plants] = usePlantsStore(useShallow((state) => [state.plants]));

  const handleEntityVariantChange = (event) => {
    updateEntityPartly(entity.id, { variant: event.target.value });
  };

  const handleRemoveEntity = () => {
    handleEntityWillBeRemoved(entity.id);
    removeEntity(entity.id);
  };

  const onChangeEditable = (newEditable) => {
    setEditable(newEditable);
    if (newEditable) {
      setFocusOnId(`entity_${entity.id}`);
    } else {
      setFocusOnId(null);
    }
  };

  const additionalContent = renderAdditionalContentFunc ? useMemo(() => renderAdditionalContentFunc(entity, plants), [entity, plants]) : null;

  return (
    <EditableCardSection
      label={entity.name}
      LabelIcon={ENTITY_TYPE[entity.type].Icon ?? null}
      onChangeEditable={onChangeEditable}
      editable={editable}
      renderChildrenFunction={(childEditable) => (childEditable ? (
        <Grid container rowSpacing={2}>
          {ENTITY_TYPE[entity.type].calculateShade && (
          <Grid item xs={12}>
            <ShadeCard selectedObject={entity} />
          </Grid>
          )}
          {!ENTITY_TYPE[entity.type].disableAttachmentSelection && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="entity-attach-type">Attached</InputLabel>
              <Select
                label="Attached"
                labelId="entity-attach-type"
                id="entity-type-select"
                value={entity.placeType}
                onChange={(event) => updateEntityPartly(entity.id, { placeType: event.target.value })}
                size="small"
              >
                {Object.values(ENTITY_PLACE_TYPE).map((type) => (
                  <MenuItem key={type.name} value={type.name}>{type.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          )}
          {entity.placeType === ENTITY_PLACE_TYPE.FLOOR.name && (
          <>
            <Grid item xs={childEditable ? 12 : 6}>
              <SliderWithButtons
                value={entity.positionX}
                setValue={(value) => updateEntityPartly(entity.id, { positionX: value })}
                min={0}
                max={100}
                label="Position X"
                step={0.1}
                transformValueToInputValue={(val) => parseFloat(sizeX * (val / 100)).toFixed(2)}
                transformInputValueToValue={(val) => (val / sizeX) * 100}
                readOnly={!childEditable}
                unitLabel="m"
              />
            </Grid>
            {!ENTITY_TYPE[entity.type].disablePositionZ && (
            <Grid item xs={childEditable ? 12 : 6}>
              <SliderWithButtons
                value={entity.positionZ}
                setValue={(value) => updateEntityPartly(entity.id, { positionZ: value })}
                min={0}
                max={100}
                label="Position Z"
                step={0.1}
                transformValueToInputValue={(val) => parseFloat(sizeZ * (val / 100)).toFixed(2)}
                transformInputValueToValue={(val) => (val / sizeZ) * 100}
                readOnly={!childEditable}
                unitLabel="m"
              />
            </Grid>
            )}
          </>
          )}
          { entity.placeType === ENTITY_PLACE_TYPE.SIDEWALL.name && (
          <Grid item xs={childEditable ? 12 : 6}>
            <SliderWithButtons
              value={entity.positionX}
              setValue={(value) => updateEntityPartly(entity.id, { positionX: value })}
              min={0}
              max={100}
              label="Position"
              step={0.1}
              readOnly={!childEditable}
              unitLabel="m"
              hideInput
            />
          </Grid>
          )}
          {!ENTITY_TYPE[entity.type].disableRotation && (
          <Grid item xs={childEditable ? 12 : 6}>
            <SliderWithButtons
              value={entity.rotationY}
              setValue={(value) => updateEntityPartly(entity.id, { rotationY: value })}
              min={0}
              max={360}
              label="Rotation Y"
              step={1}
              readOnly={!childEditable}
            />
          </Grid>
          )}
          {ENTITY_TYPE[entity.type].variants?.length ? (
            <Grid item xs={childEditable ? 12 : 6}>
              {childEditable ? (
                <FormControl fullWidth>
                  <InputLabel id="entity-type-label">Entity Type</InputLabel>
                  <Select
                    label="Entity Type"
                    labelId="entity-type-label"
                    id="entity-type-select"
                    value={ENTITY_TYPE[entity.type].variants.find((variant) => variant.id === entity.variant).id}
                    onChange={handleEntityVariantChange}
                    size="small"
                  >
                    {ENTITY_TYPE[entity.type].variants.map((variant) => (
                      <MenuItem key={variant.id} value={variant.id}>{variant.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <KeyValueLabel label="Entity Type" value={ENTITY_TYPE[entity.type].variants.find((variant) => variant.id === entity.variant).name} />
              )}
            </Grid>
          ) : null}
          {ENTITY_TYPE[entity.type].nummericAttributes && ENTITY_TYPE[entity.type].nummericAttributes.map((nummericAttribute) => (
            <Grid key={nummericAttribute?.id} item xs={childEditable ? 12 : 6}>
              <SliderWithButtons
                value={entity.nummericAttributes?.[nummericAttribute?.id] || ENTITY_TYPE[entity.type].nummericAttributes.find((attr) => attr.id === nummericAttribute.id).default}
                setValue={(value) => updateEntityPartly(entity.id, { nummericAttributes: { ...entity.nummericAttributes, [nummericAttribute.id]: value } })}
                min={nummericAttribute.min}
                max={nummericAttribute.max}
                label={nummericAttribute.name}
                step={nummericAttribute.step}
                readOnly={!childEditable}
              />
            </Grid>
          ))}
          {childEditable && ENTITY_TYPE[entity.type].colorAttributes && ENTITY_TYPE[entity.type].colorAttributes.map((colorAttributes) => (
            <Grid item xs={6} key={colorAttributes?.id}>
              <ColorPicker
                value={entity.colorAttributes?.[colorAttributes?.id] || ENTITY_TYPE[entity.type].colorAttributes.find((attr) => attr.id === colorAttributes.id).default}
                changeValue={(value) => updateEntityPartly(entity.id, { colorAttributes: { ...entity.colorAttributes, [colorAttributes.id]: value.hex } })}
                label={colorAttributes.name}
                editable={childEditable}
              />
            </Grid>
          ))}
          {childEditable && (
          <Grid item xs={12}>
            <Button onClick={handleRemoveEntity} color="error">Delete</Button>
          </Grid>
          )}
          {additionalContent ? <Grid item xs={12}>{additionalContent}</Grid> : null}
        </Grid>
      ) : null)}
    />
  );
}

export default function EntitiesList({ entities, openEditableEntityId, renderAdditionalContentFunc = null }) {
  const [editableEntityId, setEditableEntityId] = React.useState(null);

  useEffect(() => {
    setEditableEntityId(openEditableEntityId);
  }, [openEditableEntityId]);

  return (
    <Grid container rowSpacing={2}>
      {entities.map((entity) => (
        <Grid item xs={12} key={entity.id}>
          <Entity
            entity={entity}
            editable={entity.id === editableEntityId}
            setEditable={() => setEditableEntityId(entity.id)}
            renderAdditionalContentFunc={renderAdditionalContentFunc}
          />
        </Grid>
      ))}
    </Grid>
  );
}
