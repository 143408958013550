import React, { useCallback } from 'react';
import { BALCONY_WALL_TYPE } from '../../stores/balconyStore';

export default function Wall({
  position, width, height, thick, color = 'lightgrey', rotateY = 0, type = BALCONY_WALL_TYPE.SOLID.name, name = 'Wall',
  topBar = false, placedOnTop = false,
}) {
  const createBars = useCallback(() => {
    if (!type || type === BALCONY_WALL_TYPE.SOLID.name) {
      return (
        <mesh castShadow receiveShadow name={name}>
          <boxGeometry args={[width, height, thick]} />
          <meshStandardMaterial color={color} />
        </mesh>
      );
    }

    let barWidth;
    let holeWidth;
    let round = false;
    if (type === BALCONY_WALL_TYPE.LARGE_BARS.name) {
      holeWidth = 0.07;
      barWidth = 0.1;
    } else if (type === BALCONY_WALL_TYPE.SMALL_BARS.name) {
      holeWidth = 0.05;
      barWidth = 0.05;
      round = true;
    }

    const totalBars = Math.floor((width - barWidth) / (barWidth + holeWidth)) + 1;
    const bars = [];

    for (let i = 0; i < totalBars; i++) {
      const barPosition = [
        -width / 2 + barWidth / 2 + i * (barWidth + holeWidth),
        0,
        0,
      ];
      bars.push(
        <mesh key={i} castShadow receiveShadow position={barPosition} name={`WallBar${i}`} rotation={[0, rotateY, 0]}>
          {round ? <cylinderGeometry args={[barWidth / 2, barWidth / 2, height, 16]} />
            : <boxGeometry args={[thick, height, barWidth]} />}
          <meshStandardMaterial color={color} />
        </mesh>,
      );
    }
    return (<>{bars}</>);
  }, [width, height, thick, color, rotateY, type]);

  return (
    <group
      castShadow
      receiveShadow
      position={position}
      name="Wall"
      rotation={[0, rotateY, 0]}
      userData={{ placedOnTop }}
    >
      {createBars()}
      {topBar && type !== BALCONY_WALL_TYPE.SOLID.name && (
        <mesh
          position={[0, height / 2 - 0.05, 0]}
          name="TopOverlayBar"
          castShadow
          receiveShadow
        >
          <boxGeometry args={[width, 0.1, thick + 0.1]} />
          <meshStandardMaterial color={color} />
        </mesh>
      )}
    </group>
  );
}
