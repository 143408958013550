import React, { useState } from 'react';
import { Fog, PerspectiveCamera, Scene } from 'three';
import { ThreeContext } from './ThreeContext';

export function ThreeProvider({ children }) {
  const [camera] = useState(() => {
    const cam = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 600);
    cam.position.z = 3;
    cam.near = 0.4;
    cam.far = 500;
    return cam;
  });

  const [scene] = useState(() => {
    const scn = new Scene();
    scn.fog = new Fog(0xffffff, camera.near, camera.far);
    return scn;
  });

  return (
    <ThreeContext.Provider value={{ camera, scene }}>
      { children}
    </ThreeContext.Provider>
  );
}
