import React from 'react';
import * as THREE from 'three';

function Sunblind({
  width = 3,
  heightPosition = 3,
  maximalLength = 3,
  open = 1,
  tiltAngle = 0,
  colorTop,
  colorPole = 'black',
  positionZModified = false,
}) {
  const calculatedWidth = width;
  const calculatedTopHeight = 0.1;
  const calculatedLength = maximalLength * open;
  const tiltAngleRadians = Math.min(tiltAngle * 30, 30) * (Math.PI / 180);

  const offsetZ = -calculatedLength / 2; // Rückseite bleibt an Z = 0

  return (
    <group
      position={[0, heightPosition, offsetZ]}
      userData={{ placedOnTop: true }}
    >
      {/* Pivot point for the rotation */}
      <group rotation={[-tiltAngleRadians, 0, 0]} position={[0, 0, calculatedLength / 2]}>
        <mesh
          position={[0, 0, -calculatedLength / 2]}
          castShadow
          receiveShadow
        >
          <boxGeometry args={[calculatedWidth, 0.02, calculatedLength]} />
          <meshStandardMaterial color={colorTop} />
        </mesh>
        <mesh
          position={[0, -0.05, -calculatedLength]} // Slightly below and at the end
          castShadow
          receiveShadow
        >
          <boxGeometry args={[calculatedWidth, 0.1, 0.02]} />
          <meshStandardMaterial color={colorTop} />
        </mesh>

      </group>
      {positionZModified && (
      <>
        <mesh
          position={[-calculatedWidth / 2, -heightPosition / 2, calculatedLength / 2]} // Left side
          castShadow
          receiveShadow
        >
          <boxGeometry args={[0.05, heightPosition, 0.05]} />
          <meshStandardMaterial color={colorPole} />
        </mesh>
        {/* Right pole */}
        <mesh
          position={[calculatedWidth / 2, -heightPosition / 2, calculatedLength / 2]} // Right side
          castShadow
          receiveShadow
        >
          <boxGeometry args={[0.05, heightPosition, 0.05]} />
          <meshStandardMaterial color={colorPole} />
        </mesh>
      </>
      )}
    </group>
  );
}

export default Sunblind;
