import React, { useState } from 'react';
import {
  Grid, Select, MenuItem, FormControl, InputLabel, Button,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { Add } from '@mui/icons-material';
import usePlantsStore, { PLANT_TYPE } from '../../../stores/plantsStore';

export default function AddNewPlantSection({ attachToEntity, attachedIndexPosition }) {
  const [plantType, setPlantType] = useState(PLANT_TYPE.FLOWER.name);

  const [addPlant] = usePlantsStore(useShallow((state) => [state.addPlant]));

  const handlePlantTypeChange = (event) => {
    setPlantType(event.target.value);
  };

  const handleAddPlant = () => {
    setPlantType('');
    addPlant({ type: plantType, attachedEntityId: attachedIndexPosition !== undefined ? attachToEntity?.id : attachToEntity, attachedIndexPosition });
  };

  return (
    <Grid container spacing={4} alignItems="center">
      {/* <Grid item xs={7}> */}
      {/*  <FormControl fullWidth> */}
      {/*    <InputLabel id="plant-type-label">New Plant</InputLabel> */}
      {/*    <Select */}
      {/*      label="New plant" */}
      {/*      labelId="plant-type-label" */}
      {/*      id="plant-type-select" */}
      {/*      value={plantType} */}
      {/*      onChange={handlePlantTypeChange} */}
      {/*      size="medium" */}
      {/*    > */}
      {/*      {Object.values(PLANT_TYPE).map((type) => ( */}
      {/*        <MenuItem key={type.name} value={type.name}>{type.label}</MenuItem> */}
      {/*      ))} */}
      {/*    </Select> */}
      {/*  </FormControl> */}
      {/* </Grid> */}
      <Grid item xs={5}>
        <Button onClick={handleAddPlant} disabled={!plantType} variant="outlined" startIcon={<Add />}>
          Add plant
        </Button>
      </Grid>
    </Grid>
  );
}
