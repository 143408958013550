import React, { useRef, useMemo } from 'react';
import {
  CatmullRomCurve3, Vector3, TubeGeometry, MeshStandardMaterial, DoubleSide, Shape, ShapeGeometry,
} from 'three';
import * as THREE from 'three';

function BambooStem({ age }) {
  const stemRef = useRef();
  const height = age * 0.2 + 1; // height scales with age, minimum height is 1
  const segments = Math.floor(age / 2) + 3; // increase segments as age increases, minimum 3 segments

  const curve = useMemo(() => {
    const points = [];
    for (let i = 0; i <= segments; i++) {
      points.push(new Vector3(0, (height / segments) * i, 0)); // equally spaced segments
    }
    return new CatmullRomCurve3(points);
  }, [age, height, segments]);

  const geometry = useMemo(() => new TubeGeometry(curve, segments, 0.005 + age * 0.005, 8, false), [curve, age]);
  const material = new MeshStandardMaterial({ color: '#78866B', side: DoubleSide });

  return <mesh receiveShadow castShadow ref={stemRef} geometry={geometry} material={material} />;
}

function BambooLeaf({ position, scale }) {
  const leafRef = useRef();
  const length = scale * 0.3; // Leaf length scales with scale input
  const width = scale * 0.05; // Leaf width is much less than length
  const geometry = useMemo(() => {
    const shape = new THREE.Shape();
    shape.moveTo(0, 0);
    shape.lineTo(0, length);
    shape.lineTo(width, length * 0.75);
    shape.lineTo(0, 0);
    return new THREE.ShapeGeometry(shape);
  }, [length, width]);

  const material = new MeshStandardMaterial({ color: 'green', side: DoubleSide });

  return <mesh receiveShadow castShadow ref={leafRef} geometry={geometry} material={material} position={position} rotation={[0, 0, Math.PI / 2]} />;
}

function BambooPlant({ age = 5 }) {
  const ageFactor = Math.max(0.1, age);

  const leaves = useMemo(() => {
    const leafPositions = [];
    const totalLeaves = ageFactor * 2; // two leaves per year of age
    const spacing = (ageFactor * 0.2 + 1) / totalLeaves; // Leaf spacing along the stem's height
    for (let i = 0; i < totalLeaves; i++) {
      const x = 0; // Leaves grow close to the stem
      const y = spacing * i; // Position along the height of the stem
      const z = 0;
      const leafScale = 1 + (ageFactor / 10); // Scale increases with age
      leafPositions.push(
        <BambooLeaf
          key={`leaf-${i}`}
          position={[x, y, z]}
          scale={leafScale}
        />,
      );
    }
    return leafPositions;
  }, [ageFactor]);

  return (
    <group scale={ageFactor / 40}>
      <BambooStem age={ageFactor} />
      {leaves}
    </group>
  );
}

export default BambooPlant;
