import React, { useCallback, useEffect, useState } from 'react';
import { fetchBuildings } from '../../utils/osm';
import { createShape } from '../../utils/geometry';

function Water({ building }) {
  const [geometry, setGeometry] = useState(null);

  useEffect(() => {
    setGeometry(createShape(building, 0.15));
  }, [building]);

  return geometry ? (
    <mesh
      geometry={geometry}
      castShadow
      receiveShadow
      position={building.positionNormalized}
      userData={{ ignoreForShade: true }}
    >
      <meshStandardMaterial color="blue" />
    </mesh>
  ) : null;
}

export default function WaterFields({ boundingBox }) {
  const [buildings, setBuildings] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const osmBuildings = await fetchBuildings(boundingBox, `
        [out:json];
        (
          way["water"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
          relation["water"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
          way["waterway"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
          relation["waterway"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
          way["natural"="water"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
          relation["natural"="water"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
          way["leisure"="swimming_pool"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
          relation["leisure"="swimming_pool"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
        );
        out body;
        >;
        out skel qt;
      `);
      setBuildings(osmBuildings);
    }
    fetchData();
  }, [boundingBox]);

  const renderBuildings = useCallback(() => buildings.map((building, index) => (
    <Water key={building.id} building={building} />
  )), [buildings]);

  return (
    <>
      {renderBuildings()}
    </>
  );
}
