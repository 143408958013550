import React, { useContext, useRef } from 'react';
import { useShallow } from 'zustand/react/shallow';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import useScenarioStore from '../../stores/scenarioStore';
import useEntitiesStore, {
  ENTITY_PLACE_TYPE as PLACE_TYPE,
  ENTITY_TYPE,
} from '../../stores/entitiesStore';
import Plant from '../../components/3d/TomatoPlant';
import Table from '../../components/3d/Table';
import useBalconyStore from '../../stores/balconyStore';
import PlantPotSingle from '../../components/3d/PlantPotSingle';
import usePlantsStore, { PLANT_TYPE } from '../../stores/plantsStore';
import { ThreeContext } from '../../context/ThreeContext';
import BambooPlant from '../../components/3d/BambooPlant';
import TomatoPlant from '../../components/3d/TomatoPlant';
import { degreesToRadians } from '../../utils/geoCalculationUtils';

export default function BalconyPlants({}) {
  const [entities] = useEntitiesStore(useShallow((state) => [state.entities]));
  const [plants] = usePlantsStore(useShallow((state) => [state.plants]));
  const { camera, scene } = useThree();

  const renderPlant = (plant) => {
    let EntityComponent = null;
    if (plant.type === PLANT_TYPE.FLOWER.name) {
      EntityComponent = <TomatoPlant {...plant} />;
    } else if (plant.type === PLANT_TYPE.BAMBUS.name) {
      EntityComponent = <BambooPlant {...plant} />;
    } else {
      console.error(`Unknown plant type: ${plant.type}`);
    }

    const getPlantPosition = () => {
      const entity = entities.find((e) => e.id === plant.attachedEntityId);
      const plantPosition = ENTITY_TYPE[entity.type].plantPositions.find((position) => position.index === plant.attachedIndexPosition);

      // Create a vector for the plant's position
      const plantPosVector = new THREE.Vector3(plantPosition.x, plantPosition.y, plantPosition.z);

      if (entity.rotationY) {
        const rotationMatrix = new THREE.Matrix4();
        rotationMatrix.makeRotationFromEuler(new THREE.Euler(0, degreesToRadians(entity.rotationY), 0));
        plantPosVector.applyMatrix4(rotationMatrix);
      }

      plantPosVector.add(new THREE.Vector3(entity.balconyPosition.x, entity.balconyPosition.y, entity.balconyPosition.z));

      return [plantPosVector.x, plantPosVector.y, plantPosVector.z];
    };

    return (
      <group
        key={plant.id}
        name={`entity_${plant.id}`}
        position={getPlantPosition()}
        // rotation={[0, plant.rotationY * (Math.PI / 180), 0]}
        userData={{ placedOnTop: true }}
      >
        {EntityComponent}
      </group>
    );
  };

  return (
    <>
      {plants.filter((plant) => plant.attachedEntityId).map((plant) => renderPlant(plant))}
    </>
  );
}
