import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { BackSide, Color } from 'three';
import SunCalc from 'suncalc';
import { useFrame, useThree } from '@react-three/fiber';
import { Sky } from 'three/examples/jsm/objects/Sky';
import * as THREE from 'three';
import { useShallow } from 'zustand/react/shallow';
import useScenarioStore from '../../stores/scenarioStore';
import { ThreeContext } from '../../context/ThreeContext';

export default function ExternSky({ latitude, longitude }) {
  const [selectedDateTime, setSkyColor, sunPosition, sunIntensity] = useScenarioStore(useShallow((state) => [state.selectedDateTime, state.setSkyColor, state.sunPosition, state.sunIntensity]));
  const { camera, scene } = useThree();
  const sky = useRef();

  useEffect(() => {
    const esky = new Sky();
    esky.name = 'sky';
    esky.scale.setScalar(450000);
    esky.setRotationFromAxisAngle(new THREE.Vector3(1, 0, 0), Math.PI / 2);

    esky.material.uniforms.turbidity.value = 0.1;
    esky.material.uniforms.rayleigh.value = 0.2;

    esky.material.uniforms.mieCoefficient.value = 0.001;
    esky.material.uniforms.mieDirectionalG.value = 0.001;
    // esky.material.uniforms.up.value.set(0, 0.1, 0);
    // esky.setFromSphericalCoords(1, phi, theta);

    scene.add(esky);
    sky.current = esky;
  }, [latitude, longitude, selectedDateTime]);

  const calculateRange = (constant) => constant - sunIntensity * constant;

  useEffect(() => {
    if (!sky.current) return;
    if (!sunPosition) return;

    sky.current.material.uniforms.sunPosition.value.set(sunPosition[0] / 100, sunPosition[1] / 100, sunPosition[2] / 100);
    sky.current.material.uniforms.rayleigh.value = Math.max(0.1, calculateRange(1.9));
    sky.current.material.uniforms.turbidity.value = Math.max(0.1, calculateRange(2.9));
  }, [sunPosition, sunIntensity]);

  useFrame(() => {
    sky.current.position.copy(camera.position);
  });

  return (
    <mesh name="sky" ref={sky} rotation={[0, 1, 0]} />
  );
}
