/* eslint-disable no-param-reassign */
import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware';

export const CAMERA_MODE = {
  TOP_VIEW: 'TOP_VIEW', FIRST_PERSON: 'FIRST_PERSON', THIRD_PERSON: 'THIRD_PERSON',
};

const useViewStore = create(
  persist(
    immer((set) => ({
      cameraMode: CAMERA_MODE.THIRD_PERSON,
      setCameraMode: (cameraMode) => set((state) => {
        state.cameraMode = cameraMode;
      }),
      focusOnId: null,
      setFocusOnId: (focusOnId) => set((state) => {
        state.focusOnId = focusOnId;
      }),

    })),
    {
      name: 'camera-store',
      getStorage: () => localStorage,
      partialize: (state) => Object.fromEntries(
        Object.entries(state).filter(([key]) => !['focusOnId'].includes(key)),
      ),
    },
  ),
);

export default useViewStore;
