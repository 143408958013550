import React, { useRef, useMemo } from 'react';
import {
  CatmullRomCurve3, Vector3, TubeGeometry, MeshStandardMaterial, DoubleSide, Shape, ShapeGeometry,
} from 'three';
import * as THREE from 'three';

function PlantStem({ age, heightFactor, curveFactor }) {
  const stemRef = useRef();
  const curve = useMemo(() => {
    const points = [
      new Vector3(0, 0, 0),
      new Vector3(0.1 * curveFactor, age * 0.2, 0),
      new Vector3(-0.1 * curveFactor, age * 0.4, Math.random() * 0.2),
      new Vector3(0.5 * curveFactor, age * 0.6, Math.random() * 0.3),
      new Vector3(-0.2 * curveFactor, age * 0.8, Math.random() * 0.4),
      new Vector3(0, age * heightFactor, Math.random() * 0.5),
    ];
    return new CatmullRomCurve3(points);
  }, [age, heightFactor, curveFactor]);

  const geometry = useMemo(() => new TubeGeometry(curve, 55, 0.02 + age * 0.01, 8, false), [curve, age]);
  const material = new MeshStandardMaterial({ color: '#4c5115', side: DoubleSide });

  return <mesh ref={stemRef} geometry={geometry} material={material} receiveShadow castShadow />;
}

function PlantLeaf({ position, scale, rotation }) {
  const leafRef = useRef();
  const geometry = useMemo(() => {
    const shape = new THREE.Shape();
    shape.absellipse(0, 0, 0.4 * scale, 0.2 * scale, 0, Math.PI * 2, false, 0);
    return new THREE.ShapeGeometry(shape);
  }, [scale]);

  const material = new MeshStandardMaterial({ color: 'darkgreen', side: DoubleSide });

  return (
    <mesh
      receiveShadow
      castShadow
      ref={leafRef}
      geometry={geometry}
      material={material}
      position={position}
      rotation={rotation}
    />
  );
}

function TomatoPlant({ age = 5 }) {
  const ageFactor = Math.max(0.1, age);

  const branches = useMemo(() => {
    const numBranches = Math.ceil(ageFactor / 2); // Create branches based on age
    const branchGroups = [];
    for (let b = 0; b < numBranches; b++) {
      const branchRotation = Math.PI * 2 * b / numBranches; // Rotate branches around the central stem
      const branchHeightFactor = 1 - b * 0.1; // Each subsequent branch is slightly shorter
      const curveFactor = 0.5 + b * 0.1; // Slight variation in curve per branch
      const leaves = [];
      const totalLeaves = 5 + b * 3; // More leaves on lower branches
      for (let i = 2; i < totalLeaves; i++) {
        const angle = Math.PI * 2 * i / totalLeaves;
        const x = 0.9 * Math.sin(angle); // Increased offset
        const y = (i / (totalLeaves - 1)) * ageFactor * branchHeightFactor; // Vertical displacement along branch height
        const z = 0.5 * Math.cos(angle); // Increased offset
        const leafSize = (1 + i / totalLeaves) * (0.5 + 0.5 * ageFactor / 10);
        leaves.push(
          <PlantLeaf
            key={`leaf-${b}-${i}`}
            position={[x, y, z]}
            scale={leafSize}
            rotation={[-Math.PI / 2 * Math.random(), angle, 0]}
          />,
        );
      }
      branchGroups.push(
        <group key={`branch-${b}`} position={[0, 0, 0]} rotation={[0, branchRotation, 0]}>
          <PlantStem age={ageFactor} heightFactor={branchHeightFactor} curveFactor={curveFactor} />
          {leaves}
        </group>,
      );
    }
    return branchGroups;
  }, [ageFactor]);

  return (
    <group scale={ageFactor / 50}>
      {branches}
    </group>
  );
}

export default TomatoPlant;
