import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Slider,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import useScenarioStore from '../stores/scenarioStore';
import SliderWithButtons from '../components/SliderWithButtons';
import useBalconyStore, { BALCONY_TYPE } from '../stores/balconyStore';

export default function BalconyPositionSlider({ setBalconyType, balconyType }) {
  const {
    setBalconyPositionWidthRelative, balconyPositionWidthRelative, setBalconyPositionHeightRelative, balconyPositionHeightRelative, balconyPositionCollision, selectedBuilding,
  } = useScenarioStore();

  const selectedBuildingHeight = selectedBuilding.height;

  const switchBalconyType = (value) => {
    setBalconyType(value);
    if (value === BALCONY_TYPE.GARDEN) {
      setBalconyPositionHeightRelative(0);
      return;
    }

    if (balconyPositionHeightRelative === 0) {
      setBalconyPositionHeightRelative(5);
    }
  };

  const handleWidthSliderChange = (newValue) => {
    setBalconyPositionWidthRelative(newValue);
  };

  const handleHeightSliderChange = (newValue) => {
    setBalconyPositionHeightRelative(newValue);
  };

  const levels = Math.ceil(selectedBuildingHeight / 3);

  const transformValueToInputValue = (val) => parseFloat(selectedBuildingHeight * (val / 100)).toFixed(2);

  const transformInputValueToValue = (val) => (val / selectedBuildingHeight) * 100;

  const renderLevelsButtons = () => {
    const buttons = [];
    const rows = [];

    for (let i = 1; i <= levels; i++) {
      buttons.push(
        <Button key={`floor_${i}`} onClick={() => handleHeightSliderChange(transformInputValueToValue(i * 3 - 0.1))}>
          {i}
          . Floor
        </Button>,
      );

      if (i % 2 === 0 || i === levels) {
        rows.push(
          <Grid container item xs={3} spacing={2} key={`row-${i}`}>
            {buttons.splice(0, 4)}
          </Grid>,
        );
      }
    }
    return rows;
  };

  return (
    <Box padding={0}>
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <Grid container>
            <SliderWithButtons
              value={balconyPositionWidthRelative}
              setValue={handleWidthSliderChange}
              min={0}
              max={100}
              label="Position"
              step={0.1}
              hideInput
            />
            {balconyPositionCollision && (
              <Grid item xs={12}>
                <Typography variant="body2" color="error">
                  Balcony is colliding with other objects
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography id="balcony-direction-slider" gutterBottom>
            Height
          </Typography>
          <RadioGroup
            row
            value={balconyType}
            onChange={(e) => switchBalconyType(e.target.value)}
          >
            <FormControlLabel value={BALCONY_TYPE.GARDEN} control={<Radio />} label="Garden" />
            <FormControlLabel value={BALCONY_TYPE.BALCONY} control={<Radio />} label="Balcony" />
          </RadioGroup>
        </Grid>
        {balconyType === BALCONY_TYPE.BALCONY && (
          <Grid item xs={12}>
            <SliderWithButtons
              value={balconyPositionHeightRelative}
              setValue={handleHeightSliderChange}
              transformValueToInputValue={transformValueToInputValue}
              transformInputValueToValue={transformInputValueToValue}
              min={5}
              max={100}
              label="Height (m)"
              step={0.1}
            />
            <Box mt={2}>
              <ButtonGroup variant="text" fullWidth>
                <Grid container spacing={1}>
                  { renderLevelsButtons()}
                </Grid>
              </ButtonGroup>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
