import React, { useState } from 'react';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Box,
  ListItemText,
  Avatar,
  ListItemAvatar,
  Dialog,
  ListItemButton,
  ListItem,
  DialogTitle,
  List,
  IconButton,
  useTheme, useMediaQuery,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { blue } from '@mui/material/colors';
import { Add, Close } from '@mui/icons-material';
import useEntitiesStore, { ENTITY_TYPE } from '../../../stores/entitiesStore';

export default function AddNewEntitySelection({
  entityCategories, open, handleClose, handleAddEntity,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const entityTypes = Object.keys(ENTITY_TYPE)
    .filter((type) => entityCategories.includes(ENTITY_TYPE[type].category))
    .map((type) => ENTITY_TYPE[type]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullScreen={isMobile}
      maxWidth="lg"
      PaperProps={{
        elevation: 0,
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'white',
            boxShadow: 'none',
          },
        },
      }}
    >
      <Box maxWidth="700px" width="100%" marginX="auto">
        <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
          <DialogTitle>
            <IconButton onClick={handleClose} mr={1}>
              <Close />
            </IconButton>
            Add new entity
          </DialogTitle>
        </Box>
        <List sx={{ pt: 0 }}>
          {entityTypes.map((email) => (
            <ListItem disableGutters key={email.id}>
              <ListItemButton onClick={() => {
                handleAddEntity(email);
                handleClose();
              }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                    <email.Icon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={email.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Dialog>
  );
}
