import React, { useCallback, useState } from 'react';
import {
  Button, Divider, Grid, Typography,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import AddNewEntitySection from './fragments/AddNewEntitySection';
import PlantsList from './fragments/PlantsList';
import AddNewPlantSection from './fragments/AddNewPlantSection';
import EntitiesList from './fragments/EntitiesList';
import useEntitiesStore, { ENTITY_CATEGORY, ENTITY_TYPE } from '../../stores/entitiesStore';
import AddNewEntitySelection from './fragments/AddNewEntitySelection';
import usePlantsStore from '../../stores/plantsStore';

export default function PlantsContent() {
  const [entities, addEntity] = useEntitiesStore(useShallow((state) => [state.entities, state.addEntity]));
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [lastAddedEntityId, setLastAddedEntityId] = useState(null);

  const validEntityCategories = [ENTITY_CATEGORY.PLANT_CONTAINER];

  const handleAddEntity = (entityType) => {
    const id = Math.round(Math.random() * 100000);
    addEntity({ type: entityType.id, id });
    setLastAddedEntityId(id);
  };

  const renderPlantList = (entity, plants) => {
    const getNextAttachIndexPosition = () => {
      const entityPlantPositions = ENTITY_TYPE[entity.type].plantPositions;
      if (!entityPlantPositions?.length) {
        return null;
      }

      const attachedPlants = plants.filter((p) => p.attachedEntityId === entity.id);
      const usedIndices = attachedPlants.map((p) => p.attachedIndexPosition);

      for (let i = 0; i < entityPlantPositions.length; i++) {
        if (!usedIndices.includes(i)) {
          return i;
        }
      }
      return -1;
    };

    return (
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Divider>
            <Typography variant="body2">
              Plants
            </Typography>
          </Divider>
        </Grid>
        {getNextAttachIndexPosition() > -1 && (
        <Grid item xs={12}>
          <AddNewPlantSection attachToEntity={entity} attachedIndexPosition={getNextAttachIndexPosition()} />
        </Grid>
        )}
        <Grid item xs={12}>
          <PlantsList attachedToFilter={entity} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} display="flex" justifyContent="start" mx={1} mt={1}>
        <Button onClick={() => setAddDialogOpen(true)} variant="outlined">
          Add plant pot
        </Button>
        <AddNewEntitySelection handleAddEntity={handleAddEntity} entityCategories={validEntityCategories} open={addDialogOpen} handleClose={() => setAddDialogOpen(false)} />
      </Grid>
      <Grid item xs={12}>
        <EntitiesList
          openEditableEntityId={lastAddedEntityId}
          entities={entities.filter((entity) => ENTITY_TYPE[entity.type].category === ENTITY_CATEGORY.PLANT_CONTAINER)}
          renderAdditionalContentFunc={renderPlantList}
        />
      </Grid>
    </Grid>
  );
}
