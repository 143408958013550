import React from 'react';
import { Canvas } from '@react-three/fiber';

function PlantContainerTwo({
  width = 0.6,
  height = 0.2,
  length = 0.2,
}) {
  const borderThickness = 0.015;
  const soilHeight = 0.02;

  return (
    <group>
      {/* Base of the container */}
      <mesh
        position={[0, 0, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[width, 0.05, length]} />
        <meshStandardMaterial color="#78866B" />
      </mesh>

      {/* Front border */}
      <mesh
        position={[0, height / 2, -length / 2 + borderThickness / 2]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[width, height, borderThickness]} />
        <meshStandardMaterial color="#78866B" />
      </mesh>

      {/* Back border */}
      <mesh
        position={[0, height / 2, length / 2 - borderThickness / 2]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[width, height, borderThickness]} />
        <meshStandardMaterial color="#78866B" />
      </mesh>

      {/* Left border */}
      <mesh
        position={[-width / 2 + borderThickness / 2, height / 2, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[borderThickness, height, length]} />
        <meshStandardMaterial color="#78866B" />
      </mesh>

      {/* Right border */}
      <mesh
        position={[width / 2 - borderThickness / 2, height / 2, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[borderThickness, height, length]} />
        <meshStandardMaterial color="#78866B" />
      </mesh>

      {/* Soil in the middle */}
      <mesh
        position={[0, height - soilHeight / 2 - 0.05, 0]}
        castShadow
        receiveShadow
      >
        <boxGeometry args={[width - borderThickness, soilHeight, length - borderThickness]} />
        <meshStandardMaterial color="#66410b" />
      </mesh>
    </group>
  );
}

export default PlantContainerTwo;
