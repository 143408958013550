import React, { useCallback, useEffect, useState } from 'react';
import { fetchBuildings } from '../../utils/osm';
import { createShape } from '../../utils/geometry';

function Green({ building }) {
  const [geometry, setGeometry] = useState(null);

  useEffect(() => {
    setGeometry(createShape(building, 0.2));
  }, [building]);

  return geometry ? (
    <mesh
      geometry={geometry}
      castShadow
      receiveShadow
      position={building.positionNormalized}
      userData={{ ignoreForShade: true }}
    >
      <meshStandardMaterial color="green" />
    </mesh>
  ) : null;
}

export default function GreenFields({ boundingBox }) {
  const [buildings, setBuildings] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const osmGreenFeatures = await fetchBuildings(boundingBox, `
          [out:json];
          (
            way["leisure"="park"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
            relation["leisure"="park"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
            way["landuse"="grass"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
            relation["landuse"="grass"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
            way["natural"="wood"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
            relation["natural"="wood"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
          );
          out body;
          >;
          out skel qt;
        `);
      setBuildings(osmGreenFeatures.map((feature) => ({ ...feature, height: 1 })));
    }
    fetchData();
  }, [boundingBox]);
  const renderBuildings = useCallback(() => buildings.map((building, index) => (
    <Green key={building.id} building={building} />
  )), [buildings]);

  return (
    <>
      {renderBuildings()}
    </>
  );
}
