import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useShallow } from 'zustand/react/shallow';
import useScenarioStore from '../../stores/scenarioStore';
import { fetchBuildings, getNearestBuilding } from '../../utils/osm';
import { createShape } from '../../utils/geometry';
import useUiStore, { SELECTION_MODE } from '../../stores/uiStore';
import { getCenter } from '../../utils/geoCalculationUtils';

export const SELECTION_STATE = {
  SELECTED_NORMAL: 'SELECTED_NORMAL', SELECTED_HIGHLIGHTED: 'SELECTED_HIGHLIGHTED', UNSELECTED: 'unselected', TEMPORARY: 'temporary', IRRELEVANT: 'irrelevant',
};

function Building({ building, selectionState }) {
  const [geometry, setGeometry] = useState(null);
  const { selectedBuilding } = useScenarioStore();

  const selectionColor = useMemo((() => {
    if (selectionState === SELECTION_STATE.SELECTED_HIGHLIGHTED) return 'orange';
    if (selectionState === SELECTION_STATE.SELECTED_NORMAL) return 'white';
    if (selectionState === SELECTION_STATE.TEMPORARY) {
      return 'yellow';
    }
    if (selectionState === SELECTION_STATE.IRRELEVANT) {
      return '#9f9696';
    }
    return '#9f9696';
  }), [selectedBuilding, building]);

  useEffect(() => {
    if (!building) {
      return;
    }
    setGeometry(createShape(building));
  }, [building]);

  return geometry ? (
    <mesh
      key={building.id + selectionState}
      name={building.id}
      userData={building}
      geometry={geometry}
      castShadow={selectionState !== SELECTION_STATE.IRRELEVANT}
      receiveShadow={selectionState !== SELECTION_STATE.IRRELEVANT}
      position={building.positionNormalized}
    >
      <meshStandardMaterial color={selectionColor} transparent={selectionState === SELECTION_STATE.IRRELEVANT} opacity={selectionState === SELECTION_STATE.IRRELEVANT ? 0.2 : 1} />
    </mesh>
  ) : null;
}

export default function Buildings({ boundingBox }) {
  const [buildings, setBuildings] = useState([]);
  const {
    temporarySelectedBuilding, setSelectedBuilding, selectedBuilding, sourroundingModification, setLoadedBuildings, setTemporarySelectedBuilding,
  } = useScenarioStore();
  const [selectedPlace, selectionMode] = useUiStore(useShallow((state) => [state.selectedPlace, state.selectionMode]));

  useEffect(() => {
    if (!boundingBox) {
      return;
    }

    async function fetchData() {
      const osmBuildings = await fetchBuildings(boundingBox, `
        [out:json];
        (
          way["building"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
          relation["building"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
        );
        out body;
        >;
        out skel qt;
      `);
      setBuildings(osmBuildings.map((building) => ({
        ...building, isSelected: false, category: 'building', tag: 'building',
      })));

      if (!selectedBuilding) {
        const searchAddress = selectedPlace?.description.split(',')[0];

        let nearestBuilding = searchAddress
          ? osmBuildings.find((building) => building.properties.name === searchAddress
                  || `${building.properties['addr:street']} ${building.properties['addr:housenumber']}` === searchAddress)
          : null;

        if (!nearestBuilding) {
          const centerPoint = selectedPlace
            ? { lat: selectedPlace.lat, lon: selectedPlace.lon }
            : getCenter(boundingBox);
          nearestBuilding = getNearestBuilding(osmBuildings, centerPoint)?.building;
        }

        setSelectedBuilding(nearestBuilding || null);
        setTemporarySelectedBuilding(nearestBuilding || null);
      }

      setTimeout(() => {
        setLoadedBuildings(true);
      }, 1300);
    }

    fetchData();
  }, [boundingBox]);

  const getSelectionState = (building, selectionMode) => {
    if (temporarySelectedBuilding && building.id === temporarySelectedBuilding.id) {
      return SELECTION_STATE.TEMPORARY;
    }
    if (selectedBuilding && building.id === selectedBuilding.id) {
      if (selectionMode === SELECTION_MODE.BUILDING) {
        return SELECTION_STATE.SELECTED_HIGHLIGHTED;
      }
      return SELECTION_STATE.SELECTED_NORMAL;
    }
    if (selectionMode === SELECTION_MODE.BALCONY_POSITION) {
      return SELECTION_STATE.IRRELEVANT;
    }
    return SELECTION_STATE.UNSELECTED;
  };

  const renderBuildings = useCallback(() => buildings.map((building, index) => {
    const modifiedHeight = sourroundingModification[building.id]?.height;

    return (
      <Building
        key={getSelectionState(building) + building.id}
        building={modifiedHeight ? { ...building, height: modifiedHeight } : building}
        selectionState={getSelectionState(building, selectionMode)}
      />
    );
  }), [buildings, selectedBuilding, temporarySelectedBuilding, sourroundingModification, selectionMode]);

  return (
    <>
      {renderBuildings()}
    </>
  );
}
