import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Accordion,
  AccordionSummary,
  Typography, AccordionDetails, ButtonGroup,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import { ExpandMore } from '@mui/icons-material';
import dayjs from 'dayjs';
import useEntitiesStore, {
  ENTITY_PLACE_TYPE,
  ENTITY_TYPE,
} from '../../../stores/entitiesStore';
import SliderWithButtons from '../../../components/SliderWithButtons';
import ShadeCard from '../../ShadeGrade';
import usePlantsStore from '../../../stores/plantsStore';
import useUiStore from '../../../stores/uiStore';
import { PLANT_SPECIFICATION } from '../../../config/PlantSpecification';
import useShadeCalculation from '../../../hooks/useShadeCalculation';
import EditableCardSection from '../../EditableCardSection';
import KeyValueLabel from '../../../components/KeyValueLabel';
import useViewStore from '../../../stores/viewStore';

function Plant({ plant, entities }) {
  const [shade, setShade] = useState(null);
  const [removePlant, updatePlantPartly] = usePlantsStore(useShallow((state) => [state.removePlant, state.updatePlantPartly]));
  const [openPlantDetails] = useUiStore(useShallow((state) => [state.openPlantDetails]));
  const [setFocusOnId] = useViewStore(useShallow((state) => [state.setFocusOnId]));
  const shadeCalculation = useShadeCalculation();

  useEffect(() => {
    setShade(shadeCalculation.calculateShade(plant, [{
      date: dayjs('2023-04-29T12:00:00').toDate(),
    }, {
      date: dayjs('2023-06-15T12:00:00').toDate(),
    }, {
      date: dayjs('2023-09-01T12:00:00').toDate(),
    },
    ])?.totalShade);
  }, [plant, entities]);

  const handleFindPlant = () => {
    openPlantDetails(plant.id, 'FIND_PLANT');
  };

  const attachedEntity = entities.find((entity) => entity.id === plant.attachedEntityId);

  const changeIndexPosition = (position) => {
    updatePlantPartly(plant.id, { attachedIndexPosition: position.index });
    if (position.blockedByPlantId) {
      updatePlantPartly(position.blockedByPlantId, { attachedIndexPosition: plant.attachedIndexPosition });
    }
  };

  const onChangeEditable = (editable) => {
    if (editable) {
      setFocusOnId(`entity_${plant.id}`);
    } else {
      setFocusOnId(null);
    }
  };

  return (
    <EditableCardSection
      key={plant.name}
      label={plant.name}
      onChangeEditable={onChangeEditable}
      middleLabel={shade ? `${((1.0 - shade) * 100).toFixed(0)}% sun` : '-'}
      renderChildrenFunction={(editable) => (!editable ? null : (
        <Grid container rowSpacing={2}>
          {editable && (
            <>
              {editable ? (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="plant-attach-type">Attached to</InputLabel>
                    <Select
                      label="Attached to"
                      labelId="plant-attach-type"
                      id="plant-type-select"
                      value={plant.attachedEntityId}
                      onChange={(event) => updatePlantPartly(plant.id, { attachedEntityId: event.target.value, attachedIndexPosition: entities.find((entity) => entity.id === event.target.value).nextAvailableAttachmentIndex })}
                      size="small"
                    >
                      {entities.map((freeEntity) => (
                        <MenuItem key={freeEntity.name} value={freeEntity.id} key={freeEntity.id}>{freeEntity.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <KeyValueLabel label="Attached to" value={attachedEntity?.name || '-'} />
                </Grid>
              )}
              {editable ? (
                <>
                  {attachedEntity && attachedEntity.positions.length > 1 && (
                  <Grid item xs={12}>
                    <ButtonGroup variant="text">
                      {attachedEntity.positions.map((position) => (
                        <Button
                          onClick={() => changeIndexPosition(position)}
                          variant={plant.attachedIndexPosition === position.index ? 'contained' : undefined}
                        >
                          {position.name}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </Grid>
                  )}
                </>
              ) : (
                <>
                  {attachedEntity?.positions?.length > 1 && (
                  <Grid item xs={6}>
                    <KeyValueLabel label="Position" value={attachedEntity.positions.find((position) => position.index === plant.attachedIndexPosition).name} />
                  </Grid>
                  )}
                </>
              )}
              {plant.specificationId && (
              <Grid item xs={12}>
                <Typography variant="body2">
                  {PLANT_SPECIFICATION[plant.specificationId].name}
                </Typography>
              </Grid>
              )}
              <Grid item xs={editable ? 12 : 6}>
                <SliderWithButtons
                  readOnly={!editable}
                  value={plant.age}
                  setValue={(value) => updatePlantPartly(plant.id, { age: value })}
                  step={1}
                  min={0}
                  max={10}
                  label="Age"
                />
              </Grid>
              {/* <Grid item xs={12}> */}
              {/*  <Button onClick={handleFindPlant} color="info">Find plant</Button> */}
              {/* </Grid> */}
              <Grid item xs={12}>
                <Button onClick={() => removePlant(plant.id)} color="error">
                  Delete
                </Button>
              </Grid>
            </>
          )}
          {editable && (
          <Grid item xs={12}>
            <ShadeCard selectedObject={plant} />
          </Grid>
          )}
        </Grid>
      ))}
    />
  );
}

export default function PlantsList({ attachedToFilter }) {
  const [plants] = usePlantsStore(useShallow((state) => [state.plants]));
  const [entities] = useEntitiesStore(useShallow((state) => [state.entities]));

  const filteredPlants = useMemo(() => plants.filter((plant) => plant.attachedEntityId === attachedToFilter.id), [plants, attachedToFilter]);

  const getAttachedAndFreeEntities = useCallback(
    (plant) => entities
      .map((entity) => {
        const entityPlantPositions = ENTITY_TYPE[entity.type].plantPositions;
        if (!entityPlantPositions?.length) {
          return null;
        }

        const attachedPlants = plants.filter((p) => p.attachedEntityId === entity.id);
        const usedIndices = attachedPlants.map((p) => p.attachedIndexPosition);

        const positions = [];
        let nextAvailableAttachmentIndex = -1;
        for (let i = 0; i < entityPlantPositions.length; i++) {
          if (!usedIndices.includes(i)) {
            nextAvailableAttachmentIndex = i;
            positions.push(entityPlantPositions[i]);
          } else {
            positions.push({ ...entityPlantPositions[i], blockedByPlantId: attachedPlants.find((p) => p.attachedIndexPosition === i).id });
          }
        }

        const canAttach = nextAvailableAttachmentIndex > -1 || entity.id === plant.attachedEntityId;
        return canAttach ? {
          ...entity, nextAvailableAttachmentIndex, positions,
        } : null;
      })
      .filter(Boolean),
    [filteredPlants, entities],
  );

  return (
    <Grid container rowSpacing={2}>
      {filteredPlants.map((plant) => (
        <Grid item xs={12} key={plant.id}>
          <Plant plant={plant} entities={getAttachedAndFreeEntities(plant)} />
        </Grid>
      ))}
    </Grid>
  );
}
