import React, { useRef } from 'react';
import useScenarioStore from '../../stores/scenarioStore';

export default function Table({
  position, width = 1, length = 1, colorLegs, colorTop,
}) {
  const meshRef = useRef();
  const { setSelectedObject, setPreviewData } = useScenarioStore();

  const legPosition = width / 2 - 0.05;
  const legLength = length / 2 - 0.05;

  return (
    <group
      position={position}
      onClick={() => {
        setSelectedObject(meshRef.current);
        setPreviewData({
          type: 'Table',
          data: {
            position,
          },
        });
      }}
    >
      <mesh ref={meshRef} position={[0, 0.71, 0]} name="Table top" castShadow receiveShadow>
        <boxGeometry args={[width + 0.05, 0.05, length + 0.05]} />
        <meshStandardMaterial color={colorTop} />
      </mesh>

      {[
        [-legPosition, 0.4, -legLength],
        [legPosition, 0.4, -legLength],
        [-legPosition, 0.4, legLength],
        [legPosition, 0.4, legLength],
      ].map((pos, i) => (
        <mesh key={i} position={pos} name="Table legs" castShadow receiveShadow>
          <boxGeometry args={[0.07, 0.65, 0.07]} />
          <meshStandardMaterial color={colorLegs} />
        </mesh>
      ))}
    </group>
  );
}
