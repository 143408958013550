import React from 'react';
import proj4 from 'proj4';
import Buildings from './Buildings';
import WaterFields from './WaterFields';
import GreenFields from './GreenFields';
import Streets from './Streets';
import Trees from './Trees';
import BuildingLabels from './BuildingLabels';

const projection = proj4('EPSG:4326', 'EPSG:3857');

export default function Sourrounding({ boundingBox }) {
  return (
    <>
      <mesh name="groundfloor" receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 0]}>
        <planeGeometry attach="geometry" args={[10000, 10000]} />
        <meshStandardMaterial attach="material" color="grey" />
      </mesh>
      <Buildings boundingBox={boundingBox} />
      <Trees boundingBox={boundingBox} />
      <WaterFields boundingBox={boundingBox} />
      <GreenFields boundingBox={boundingBox} />
      <Streets boundingBox={boundingBox} />
      <BuildingLabels boundingBox={boundingBox} />
    </>
  );
}
