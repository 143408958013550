import React, {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  FirstPersonControls,
  OrbitControls,
  OrthographicCamera,
  PerspectiveCamera, TrackballControls,
} from '@react-three/drei';
import { Vector3 } from 'three';
import { useShallow } from 'zustand/react/shallow';
import { useThree } from '@react-three/fiber';
import { useMediaQuery, useTheme } from '@mui/material';
import { gsap } from 'gsap';
import useSelectionModeClick from '../hooks/useSelectionModeClick';
import useViewStore, { CAMERA_MODE } from '../stores/viewStore';
import useScenarioStore from '../stores/scenarioStore';
import { ThreeContext } from '../context/ThreeContext';
import useUiStore, { SELECTION_MODE, VIEW_EVENTS } from '../stores/uiStore';

export default function CameraControl({ camera, scene }) {
  const [cameraMode, focusOnId] = useViewStore(
    useShallow((state) => [state.cameraMode,
      state.focusOnId]),
  );
  const [
    balconyCalculatedPosition, balconyCalculatedRotationY, loadedBuildings, selectedBuilding, temporarySelectedBuilding,
  ] = useScenarioStore(useShallow((state) => [state.balconyCalculatedPosition,
    state.balconyCalculatedRotationY, state.loadedBuildings, state.selectedBuilding, state.temporarySelectedBuilding]));
  const [selectionMode] = useUiStore(useShallow((state) => [state.selectionMode]));
  const [focusPosition, setFocusPosition] = useState(new Vector3(balconyCalculatedPosition.x, balconyCalculatedPosition.y, balconyCalculatedPosition.z));
  const focusPositionRef = useRef(focusPosition);
  const [triggerRepositionCamera, setTriggerRepositionCamera] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const controlRef = useRef();

  if (!balconyCalculatedPosition) {
    return null;
  }

  useEffect(() => {
    focusPositionRef.current = focusPosition;
  }, [focusPosition]);

  useEffect(() => {
    const updateFocusPosition = () => {
      const getFocusPosition = () => {
        if (!focusOnId || !loadedBuildings) {
          return (new Vector3(balconyCalculatedPosition.x, balconyCalculatedPosition.y, balconyCalculatedPosition.z));
        }
        let object = scene.getObjectById(focusOnId);
        if (!object) {
          object = scene.getObjectByName(focusOnId);
        }
        if (!object) {
          console.error('Object not found:', focusOnId);
          return (new Vector3(balconyCalculatedPosition.x, balconyCalculatedPosition.y, balconyCalculatedPosition.z));
        }
        const newPosition = new Vector3();
        object.getWorldPosition(newPosition);

        return (newPosition);
      };

      const newFocusPosition = getFocusPosition();
      if (newFocusPosition.equals(focusPositionRef.current)) {
        return;
      }
      if (Math.abs(newFocusPosition.y - focusPositionRef.current.y) > 3) {
        setTriggerRepositionCamera(Math.random());
      }
      setFocusPosition(newFocusPosition);
    };

    const intervalId = setInterval(updateFocusPosition, isMobile ? 90 : 30);
    return () => clearInterval(intervalId);
  }, [focusOnId, loadedBuildings, scene, selectionMode]);

  useEffect(() => {
    if (selectionMode === SELECTION_MODE.BALCONY_POSITION || selectionMode === SELECTION_MODE.BUILDING) {
      setTriggerRepositionCamera(Math.random());
    }
  }, [focusPosition, selectionMode]);

  const getCalculatedPosition = useCallback((additionalX, additionalY, additionalZ) => {
    const additionalPosition = new Vector3(additionalX, additionalY, additionalZ);
    additionalPosition.applyAxisAngle(new Vector3(0, 1, 0), balconyCalculatedRotationY);
    return new Vector3().addVectors(new Vector3(focusPosition.x, focusPosition.y + 1, focusPosition.z), additionalPosition);
  }, [focusPosition, balconyCalculatedPosition]);

  const animateCameraToPosition = (controls, newPosition, duration = 1) => {
    // Animate camera position
    // gsap.to(camera.position, {
    //   x: newPosition.x,
    //   y: newPosition.y,
    //   z: newPosition.z,
    //   duration,
    //   onUpdate: () => camera.updateProjectionMatrix(),
    // });

    // If using OrbitControls, animate the target position as well
    if (controls && controls.target) {
      gsap.to(controls.target, {
        x: newPosition.x,
        y: newPosition.y,
        z: newPosition.z,
        duration,
        onUpdate: () => controls.update(),
      });
    }
  };

  useEffect(() => {
    if (controlRef.current && focusPosition) {
      // camera.lookAt(focusPosition.x, focusPosition.y, focusPosition.z);
      // controlRef.current.target = new Vector3(focusPosition.x, focusPosition.y, focusPosition.z);
      animateCameraToPosition(controlRef.current, focusPosition, 0.3);
    }
  }, [focusPosition]);

  const renderMe = useMemo(() => {
    if (selectionMode === SELECTION_MODE.BUILDING || selectionMode === SELECTION_MODE.BALCONY_POSITION) {
      camera.position.set(focusPosition.x - 0.1, focusPosition.y + (isMobile ? 101 : 152), focusPosition.z);
      camera.rotation.set(-Math.PI / 2, 0, 0);
      camera.setFocalLength(55);

      return (
        <group key="selection-mode-camera-building">
          <OrbitControls
            ref={controlRef}
            key={`top-view-orbit-controls-selection${selectionMode}`}
            target={focusPosition}
            enableRotate={false}
            enableZoom
            maxDistance={isMobile ? 350 : 400}
            mouseButtons={{
              LEFT: 2,
              MIDDLE: 1,
              RIGHT: 0,
            }}
            touches={{
              ONE: 1,
              TWO: 0,
            }}
          />
        </group>
      );
    }
    camera.setFocalLength(isMobile ? 55 : 35);

    if (cameraMode === CAMERA_MODE.TOP_VIEW) {
      camera.position.set(balconyCalculatedPosition.x - 0.1, balconyCalculatedPosition.y + 50, balconyCalculatedPosition.z);
      camera.rotation.set(-Math.PI / 2, 0, 0);

      return (
        <group key="top-view-camera">
          <OrbitControls
            ref={controlRef}
            key="top-view-orbit-controls"
            target={new Vector3(balconyCalculatedPosition.x, balconyCalculatedPosition.y, balconyCalculatedPosition.z)}
            enableRotate={false}
            enableZoom
            mouseButtons={{
              LEFT: 2,
              MIDDLE: 1,
              RIGHT: 0,
            }}
            touches={{
              ONE: 1,
              TWO: 0,
            }}
          />
        </group>
      );
    }

    if (cameraMode === CAMERA_MODE.FIRST_PERSON) {
      const v3 = getCalculatedPosition(0, 0.5, 0.5);
      camera.position.set(v3.x, v3.y, v3.z);
      camera.setFocalLength(isMobile ? 44 : 33);
      return (
        <group key="first-person-camera">
          <OrbitControls
            ref={controlRef}
            key="first-view-orbit-controls"
            target={getCalculatedPosition(0, 0.5, -0.2)}
          // target={new Vector3(balconyCalculatedPosition.x, balconyCalculatedPosition.y + 1, balconyCalculatedPosition.z)}
          // enablePan={false}
            enableZoom
            maxDistance={4}
            minDistance={0.1}
          />
        </group>
      );
    }
    if (cameraMode === CAMERA_MODE.THIRD_PERSON) {
      const mobileFactor = isMobile ? 0.85 : 1;
      const v3 = getCalculatedPosition(0, (focusOnId ? 2 : 4) * mobileFactor, (focusOnId ? -5 : -10) * mobileFactor);
      camera.position.set(v3.x, v3.y, v3.z);

      return (
        <group key="third-camera">
          <OrbitControls
            ref={controlRef}
            key="third-view-orbit-controls"
            // target={new Vector3(balconyCalculatedPosition.x, balconyCalculatedPosition.y + 1, balconyCalculatedPosition.z)}
            target={getCalculatedPosition(0, 0, 0)}
            enablePan
            enableZoom
            maxDistance={230}
            minDistance={1}
          />
        </group>
      );
    }
    console.error('Unknown camera mode');

    return null;
  }, [cameraMode, balconyCalculatedPosition, selectionMode, selectedBuilding, triggerRepositionCamera]);

  return renderMe;
}
