import React, { useState } from 'react';
import {
  Box, Button, Divider, Grid,
} from '@mui/material';
import { useShallow } from 'zustand/react/shallow';
import AddNewEntitySection from './fragments/AddNewEntitySection';
import EntitiesList from './fragments/EntitiesList';
import useEntitiesStore, { ENTITY_CATEGORY, ENTITY_TYPE } from '../../stores/entitiesStore';
import AddNewEntitySelection from './fragments/AddNewEntitySelection';

export default function EntitiesContent() {
  const [entities, addEntity] = useEntitiesStore(useShallow((state) => [state.entities, state.addEntity]));
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [lastAddedEntityId, setLastAddedEntityId] = useState(null);

  const validCategories = Object.keys(ENTITY_CATEGORY)
    .filter((category) => ENTITY_CATEGORY[category].id !== ENTITY_CATEGORY.PLANT_CONTAINER.id)
    .map((category) => ENTITY_CATEGORY[category]);

  const handleAddEntity = (entityType) => {
    const id = Math.round(Math.random() * 100000);
    addEntity({ type: entityType.id, id });
    setLastAddedEntityId(id);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} display="flex" justifyContent="start" mx={1} mt={1}>
        <Button onClick={() => setAddDialogOpen(true)} variant={entities?.length === 0 ? 'contained' : 'outlined'}>
          Add Entity
        </Button>
        <AddNewEntitySelection handleAddEntity={handleAddEntity} entityCategories={validCategories} open={addDialogOpen} handleClose={() => setAddDialogOpen(false)} />
      </Grid>
      <Divider />
      <Grid item xs={12}>
        <EntitiesList
          openEditableEntityId={lastAddedEntityId}
          entities={entities.filter((entity) => validCategories.includes(ENTITY_TYPE[entity.type].category))}
        />
      </Grid>
    </Grid>
  );
}
