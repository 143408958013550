import React from 'react';
import { createRoot } from 'react-dom/client';
import './styles.css';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ThreeProvider } from './context/ThreeProvider';

createRoot(document.getElementById('root')).render(<ThreeProvider>
  <App />
</ThreeProvider>);
