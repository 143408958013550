import React, { memo, useState } from 'react';
import {
  AppBar,
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
  Fab,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import { useShallow } from 'zustand/react/shallow';
import Scenario from './fragments/Scenario';
import Sidebar from './fragments/sidebar/Sidebar';
import { ThreeProvider } from './context/ThreeProvider';
import Overlay from './fragments/overlay/Overlay';
import DateTimeSlider from './fragments/DateTimeSlider';
import useUiStore, { VIEW_EVENTS } from './stores/uiStore';
import useScenarioStore from './stores/scenarioStore';
import LoadingOverlay from './fragments/overlay/LoadingOverlay';

const SidebarComponent = memo(() => <Sidebar />);
const ScenarioComponent = memo(() => <Scenario />);

export default function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [addNewEvent, showShadowLayer, setShowShadowLayer] = useUiStore(useShallow((state) => [state.addNewEvent, state.showShadowLayer, state.setShowShadowLayer]));
  const [
    loadedBuildings,
  ] = useScenarioStore(useShallow((state) => [state.loadedBuildings]));

  const handleFullscreenToggle = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 0,
            marginLeft: drawerOpen ? '250px' : 0,
            pointerEvents: drawerOpen ? 'none' : 'auto',
          }}
          onClick={(e) => {
            if (drawerOpen) {
              setDrawerOpen(false);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={8} xl={9}>
              <Box height={isMobile ? (isFullscreen ? 'calc(100vh - 50px)' : '35vh') : 'calc(100vh - 50px)'} id="threejs-div">
                {(!isMobile || isFullscreen) && (
                <Box height="50px">
                  <DateTimeSlider />
                </Box>
                )}
                <ScenarioComponent />

                {isMobile && (
                <>
                  <Fab
                    color="primary"
                    size={isFullscreen ? 'large' : 'small'}
                    style={{ position: 'absolute', bottom: isFullscreen ? '15vh' : '69vh', left: '3vw' }}
                    onClick={handleFullscreenToggle}
                  >
                    {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                  </Fab>
                  {/* <Fab TODO: DOESNT WORK :( RERENDERS CAMERA CONTROL */}
                  {/*  color="primary" */}
                  {/*  size="small" */}
                  {/*  style={{ position: 'absolute', bottom: isFullscreen ? '22vh' : '76vh', right: '5vw' }} */}
                  {/*  onClick={() => addNewEvent(VIEW_EVENTS.ZOOM_IN)} */}
                  {/* > */}
                  {/*  <ZoomIn /> */}
                  {/* </Fab> */}
                  {/* <Fab */}
                  {/*  color="primary" */}
                  {/*  size="small" */}
                  {/*  style={{ position: 'absolute', bottom: isFullscreen ? '15vh' : '69vh', right: '5vw' }} */}
                  {/*  onClick={() => addNewEvent(VIEW_EVENTS.ZOOM_OUT)} */}
                  {/* > */}
                  {/*  <ZoomOut /> */}
                  {/* </Fab> */}
                </>
                )}
                {/* {isMobile && isFullscreen && ( */}
                {/* <Box height="50px"> */}
                {/*  <DateTimeSlider /> */}
                {/* </Box> */}
                {/* )} */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4} xl={3}>
              <Box height={isMobile ? (isFullscreen ? 0 : 'calc(65vh)') : 'calc(100vh)'} overflow="auto">
                <SidebarComponent />
              </Box>
            </Grid>
            <Overlay />
            <LoadingOverlay />
          </Grid>
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
