import React, { useEffect, useMemo } from 'react';
import {
  Box, Card, CardContent, Grid, IconButton, Typography,
} from '@mui/material';
import { Close, Edit } from '@mui/icons-material';
import BorderLeftIcon from '@mui/icons-material/BorderLeft';
import useScenarioStore from '../stores/scenarioStore';
import ShadeCard from './ShadeGrade';
import SliderWithButtons from '../components/SliderWithButtons';

export default function EditableCardSection({
  disableEdit = false, label = null, LabelIcon = null, middleLabel = null,
  renderChildrenFunction, onChangeEditable, editable: controlledEditable = false,
}) {
  const [editable, setEditable] = React.useState(controlledEditable);

  useEffect(() => {
    setEditable(controlledEditable);
  }, [controlledEditable]);

  const switchEditable = () => {
    if (disableEdit) {
      return;
    }
    if (onChangeEditable) {
      onChangeEditable(!editable);
    }
    setEditable(!editable);
  };

  const chilrenContent = useMemo(() => renderChildrenFunction(editable), [editable, renderChildrenFunction]);

  return (
    <Card
      elevation={0}
    >
      <Grid
        paddingX={1}
        paddingY={1}
        container
        rowSpacing={1}
        onClick={(disableEdit || !editable) ? switchEditable : null}
        sx={!editable && !disableEdit ? {
          cursor: 'pointer',

        } : null}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={switchEditable}
            sx={!disableEdit ? {
              cursor: 'pointer',
              '&:hover': {
                textDecoration: !editable ? 'underline' : 'none',
              },
            } : null}
          >
            <Box alignItems="center" display="flex">
              {LabelIcon !== null && (
                <Box marginRight={1}>
                  <LabelIcon />
                </Box>
              )}
              <Typography fontWeight={editable ? 'bold' : ''}>{label}</Typography>
            </Box>
            {(editable && middleLabel) ? (<Box />) : (
              <Typography fontWeight={editable ? 'bold' : ''} variant="caption">{middleLabel}</Typography>
            )}
            {disableEdit ? (<Box />) : (
              <IconButton color="primary">
                {!editable ? <Edit /> : <Close />}
              </IconButton>
            )}
          </Box>
        </Grid>
        {chilrenContent != null && (
          <Grid item xs={12} py={1}>
            <Grid container rowSpacing={1} pt={2}>
              {chilrenContent}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Card>
  );
}
