import { debounce, throttle } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';
import { Color } from 'three';
import { useShallow } from 'zustand/react/shallow';
import dayjs from 'dayjs';
import usePositionAtBalcony from '../../hooks/usePositionAtBalcony';
import useEntitiesStore, { ENTITY_PLACE_TYPE } from '../../stores/entitiesStore';
import useShadeCalculation from '../../hooks/useShadeCalculation';
import useScenarioStore from '../../stores/scenarioStore';
import useBalconyStore from '../../stores/balconyStore';

export default function ShadowVisualizer({ }) {
  const positionAtBalcony = usePositionAtBalcony();
  const { scene } = useThree();
  const [spheres, setSpheres] = useState([]);
  const shadeCalculation = useShadeCalculation();
  const [
    loadedBuildings, balconyDirection, balconyType, balconyPositionWidthRelative, balconyPositionHeightRelative, selectedDateTime,
  ] = useScenarioStore(useShallow((state) => [state.loadedBuildings, state.balconyDirection, state.balconyType, state.balconyPositionWidthRelative, state.balconyPositionHeightRelative, state.selectedDateTime]));

  const [
    sizeX, sizeZ, balconyState,
  ] = useBalconyStore(useShallow((state) => [state.sizeX, state.sizeZ, state]));

  const [
    entities,
  ] = useEntitiesStore(useShallow((state) => [state.entities]));

  const throttledUpdateRef = useRef();

  useEffect(() => {
    throttledUpdateRef.current = debounce(() => {
      spheres.forEach((sphere, i) => {
        const shade = shadeCalculation.calculateShade(
          { id: sphere.props.name.replace('entity_', ''), position: sphere.position },
          [{
            date: dayjs('2023-04-29T12:00:00').toDate(),
          }, {
            date: dayjs('2023-06-15T12:00:00').toDate(),
          }, {
            date: dayjs('2023-09-01T12:00:00').toDate(),
          },
          ],
        ).totalShade;
        const color = new THREE.Color(`hsl(${((1.0 - shade) * 100)}, 100%, 50%)`);
        const mesh = scene.getObjectByName(sphere.props.name);
        if (mesh) {
          mesh.material.color = color;
        }
      });
    }, 500);
  }, [spheres, scene]);

  useEffect(() => {
    if (!loadedBuildings) {
      console.log('No loaded buildings');
      return;
    }

    const stepSize = 0.25;
    const step = 100 / ((positionAtBalcony.getSideWallLength()) / stepSize);

    const newSpheres = [];
    for (let i = 0; i < 100; i += step) {
      const position = positionAtBalcony.getEntityPosition(i, 0, ENTITY_PLACE_TYPE.SIDEWALL.name, stepSize, stepSize);
      const modifiedPosition = new THREE.Vector3(position[0], position[1] + 0.05, position[2]);
      newSpheres.push(
        <mesh key={i} position={modifiedPosition} name={`entity_sphere_${i}`} userData={{ ignoreForShade: true }}>
          <boxGeometry args={[stepSize - 0.05, 0.05, stepSize - 0.05]} />
          <meshBasicMaterial color="white" />
        </mesh>,
      );
    }

    const stepX = 100.0 / (sizeX / stepSize);
    const stepZ = 100.0 / ((sizeZ - stepSize) / stepSize);
    for (let x = 0; x < 100; x += stepX) {
      for (let z = 0; z < 100; z += stepZ) {
        const position = positionAtBalcony.getEntityPosition(x, z, ENTITY_PLACE_TYPE.FLOOR.name, stepSize, stepSize);
        const modifiedPosition = new THREE.Vector3(position[0], position[1] + 0.05, position[2]);

        newSpheres.push(
          <mesh key={`${x}_${z}`} position={modifiedPosition} name={`entity_sphere_${x}_${z}`} userData={{ ignoreForShade: true }}>
            <boxGeometry args={[stepSize - 0.05, 0.15, stepSize - 0.05]} />
            <meshBasicMaterial color="white" />
          </mesh>,
        );
      }
    }

    setSpheres(newSpheres);
  }, [loadedBuildings, balconyState, balconyDirection,
    balconyType, balconyPositionWidthRelative, balconyPositionHeightRelative,
    sizeX, sizeZ]);

  useEffect(() => {
    if (spheres.length === 0 || !loadedBuildings) {
      return;
    }
    throttledUpdateRef.current();
  }, [spheres.length, loadedBuildings, entities]);

  return (
    <>
      {spheres}
    </>
  );
}
