/* eslint-disable no-param-reassign */
import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import dayjs from 'dayjs';
import { persist } from 'zustand/middleware';

const useScenarioStore = create(
  persist(
    immer((set, get) => ({
      walls: [],
      addWall: () => set((state) => {
        state.walls.push({});
      }),
      cameraMovementAllowed: true,
      setCameraMovementAllowed: (cameraMovementAllowed) => set((state) => {
        state.cameraMovementAllowed = cameraMovementAllowed;
      }),
      sunPosition: [0, 0, 0],
      setSunPosition: (sunPosition) => set((state) => {
        state.sunPosition = sunPosition;
      }),
      sunIntensity: 0.5,
      setSunIntensity: (sunIntensity) => set((state) => {
        state.sunIntensity = sunIntensity;
      }),
      selectedDateTime: dayjs(),
      setSelectedDateTime: (selectedDateTime) => set((state) => {
        state.selectedDateTime = selectedDateTime;
      }),
      balconyDirection: 0,
      setBalconyDirection: (balconyDirection) => set((state) => {
        state.balconyDirection = balconyDirection;
      }),
      balconyPositionWidthRelative: 9.3,
      setBalconyPositionWidthRelative: (balconyPositionWidthRelative) => set((state) => {
        state.balconyPositionWidthRelative = balconyPositionWidthRelative;
      }),
      balconyPositionHeightRelative: 60,
      setBalconyPositionHeightRelative: (balconyPositionHeightRelative) => set((state) => {
        state.balconyPositionHeightRelative = balconyPositionHeightRelative;
      }),
      balconyCalculatedPosition: {
        x: 8.053472491580672,
        y: 8.4,
        z: -28.331630776557986,
      },
      setBalconyCalculatedPosition: (balconyCalculatedPosition) => set((state) => {
        state.balconyCalculatedPosition = { x: balconyCalculatedPosition.x, y: balconyCalculatedPosition.y, z: balconyCalculatedPosition.z };
      }),
      balconyCalculatedRotationY: 2.1527934835543494,
      setBalconyCalculatedRotationY: (balconyCalculatedRotationY) => set((state) => {
        state.balconyCalculatedRotationY = balconyCalculatedRotationY;
      }),
      balconyPositionCollision: false,
      setBalconyPositionCollision: (balconyPositionCollision) => set((state) => {
        state.balconyPositionCollision = balconyPositionCollision;
      }),
      cameraPosition: [0, 0, 0],
      setCameraPosition: (cameraPosition) => set((state) => {
        state.cameraPosition = cameraPosition;
      }),
      cameraRotation: [0, 0, 0],
      setCameraRotation: (cameraRotation) => set((state) => {
        state.cameraRotation = cameraRotation;
      }),
      selectedObject: null,
      setSelectedObject: (selectedObject) => set((state) => {
        state.selectedObject = selectedObject;
      }),
      previewData: null,
      setPreviewData: (previewData) => set((state) => {
        state.previewData = previewData;
      }),
      updatedScenarioFlag: 0,
      triggerUpdatedScenarioFlag: () => set((state) => {
        state.updatedScenarioFlag = Math.random();
      }),
      selectedBuilding: {
        id: 'way/15218371',
        type: 'Polygon',
        properties: {
          'addr:city': 'Berlin',
          'addr:postcode': '10789',
          'addr:street': 'Breitscheidplatz',
          amenity: 'place_of_worship',
          architect: 'Egon Eiermann',
          building: 'church',
          'building:colour': '#5D5E65',
          'building:levels': '7',
          'building:material': 'concrete',
          denomination: 'protestant',
          height: '12',
          heritage: 'yes',
          'loc_name:de': 'Puderdose',
          'loc_name:lb': 'Pudderdous',
          min_height: '1.5',
          name: 'Neue Kaiser-Wilhelm-Gedächtniskirche',
          'name:de': 'Neue Kaiser-Wilhelm-Gedächtniskirche',
          'name:en': 'New Kaiser Wilhelm Memorial Church',
          opening_hours: 'Mo-Su,PH 09:00-19:00',
          place_of_worship: 'church',
          religion: 'christian',
          'roof:colour': '#5F5942',
          'roof:height': '1',
          'roof:shape': 'flat',
          start_date: '1961-12-17',
          website: 'https://www.gedaechtniskirche-berlin.de/',
          wheelchair: 'limited',
          wikidata: 'Q153951',
          wikipedia: 'de:Kaiser-Wilhelm-Gedächtniskirche',
          id: 'way/15218371',
        },
        height: 14,
        coordinates: [
          {
            x: 2.1081270407885313,
            y: -37.36674465966644,
          },
          {
            x: 10.020926935598254,
            y: -25.341699390963186,
          },
          {
            x: 13.877461085096002,
            y: -24.648401511076372,
          },
          {
            x: 17.300705840811133,
            y: -23.985469388309866,
          },
          {
            x: 20.46923977136612,
            y: -23.375945728155784,
          },
          {
            x: 24.580641673877835,
            y: -22.636029581481125,
          },
          {
            x: 36.611091637052596,
            y: -30.887297587119974,
          },
          {
            x: 39.25214643776417,
            y: -44.55655992042739,
          },
          {
            x: 31.119140604510903,
            y: -57.16383534739725,
          },
          {
            x: 27.386049021966755,
            y: -57.90184233232867,
          },
          {
            x: 25.779774851165712,
            y: -58.2173084190581,
          },
          {
            x: 16.873678737320006,
            y: -59.984583641635254,
          },
          {
            x: 4.949546154588461,
            y: -51.52039143408183,
          },
          {
            x: 3.528831461444497,
            y: -44.443569921073504,
          },
          {
            x: 2.1081270407885313,
            y: -37.36674465966644,
          },
        ],
        geoCoordinates: [
          [
            13.3346806,
            52.5047409,
          ],
          [
            13.334855,
            52.5048145,
          ],
          [
            13.3348639,
            52.5048493,
          ],
          [
            13.3348725,
            52.5048802,
          ],
          [
            13.3348804,
            52.5049088,
          ],
          [
            13.3348899,
            52.5049459,
          ],
          [
            13.3347643,
            52.5050523,
          ],
          [
            13.3345621,
            52.5050732,
          ],
          [
            13.3343792,
            52.5049975,
          ],
          [
            13.3343696,
            52.5049638,
          ],
          [
            13.3343655,
            52.5049493,
          ],
          [
            13.3343425,
            52.5048689,
          ],
          [
            13.3344712,
            52.5047635,
          ],
          [
            13.3345759,
            52.5047522,
          ],
          [
            13.3346806,
            52.5047409,
          ],
        ],
        coordinatesNormalized: [
          {
            x: -16.28957217882077,
            y: 2.5930169084963097,
          },
          {
            x: -8.376772284011047,
            y: 14.618062177199562,
          },
          {
            x: -4.520238134513299,
            y: 15.311360057086375,
          },
          {
            x: -1.0969933787981674,
            y: 15.974292179852881,
          },
          {
            x: 2.0715405517568186,
            y: 16.583815840006963,
          },
          {
            x: 6.1829424542685345,
            y: 17.323731986681622,
          },
          {
            x: 18.213392417443295,
            y: 9.072463981042773,
          },
          {
            x: 20.854447218154867,
            y: -4.596798352264642,
          },
          {
            x: 12.721441384901603,
            y: -17.204073779234506,
          },
          {
            x: 8.988349802357455,
            y: -17.942080764165922,
          },
          {
            x: 7.382075631556411,
            y: -18.257546850895352,
          },
          {
            x: -1.5240204822892949,
            y: -20.024822073472507,
          },
          {
            x: -13.44815306502084,
            y: -11.560629865919083,
          },
          {
            x: -14.868867758164804,
            y: -4.483808352910756,
          },
          {
            x: -16.28957217882077,
            y: 2.5930169084963097,
          },
        ],
        centerNormalized: {
          x: 18.3976992196093,
          y: -39.95976156816275,
        },
        positionNormalized: [
          18.3976992196093,
          0,
          -39.95976156816275,
        ],
        isSelected: false,
        category: 'building',
        tag: 'building',
      },
      setSelectedBuilding: (selectedBuilding) => set((state) => {
        state.selectedBuilding = selectedBuilding;
        if (selectedBuilding?.id && state.sourroundingModification[selectedBuilding.id]) {
          state.selectedBuilding = { ...selectedBuilding, ...state.sourroundingModification[selectedBuilding.id] };
        }
      }),
      temporarySelectedBuilding: null,
      setTemporarySelectedBuilding: (temporarySelectedBuilding) => set((state) => {
        state.temporarySelectedBuilding = temporarySelectedBuilding;
      }),
      skyColor: null,
      setSkyColor: (skyColor) => set((state) => {
        state.skyColor = skyColor;
      }),
      sourroundingModification: {
        'way/15218371': {
          height: 12,
        },
      },
      setSourroundingModification: (id, key, value) => set((state) => {
        if (!state.sourroundingModification[id]) {
          state.sourroundingModification[id] = {};
        }
        state.sourroundingModification[id][key] = value;
        if (state.selectedBuilding.id === id) {
          state.selectedBuilding[key] = value;
        }
      }),
      loadedBuildings: false,
      setLoadedBuildings: (loadedBuildings) => set((state) => {
        state.loadedBuildings = loadedBuildings;
      }),
    })),
    {
      name: 'scenario-store',
      getStorage: () => localStorage,
      partialize: (state) => Object.fromEntries(
        Object.entries(state).filter(([key]) => !['loadedBuildings'].includes(key)),
      ),
    },
  ),
);

export default useScenarioStore;
