import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Box, Typography } from '@mui/material';

export default function ColorPicker({
  value, changeValue, label, editable,
}) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    if (!editable) {
      return;
    }
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <Box
        style={{
          backgroundColor: value,
          width: '36px',
          height: '19px',
          borderRadius: '4px',
          cursor: editable ? 'pointer' : 'inherit',
          border: '1px solid #aaa',
          margin: '5px',
        }}
        onClick={handleClick}
      />
      {displayColorPicker ? (
        <Box style={{ position: 'absolute', zIndex: '2' }}>
          <Box
            style={{
              position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px',
            }}
            onClick={handleClose}
          />
          <SketchPicker color={value} onChangeComplete={changeValue} />
        </Box>
      ) : null}
      <Typography variant="body1" style={{ marginLeft: '10px' }}>{label}</Typography>
    </Box>
  );
}
