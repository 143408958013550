/* eslint-disable no-param-reassign */
import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware';

export const BALCONY_WALL_TYPE = { SOLID: { name: 'SOLID', label: 'Solid' }, SMALL_BARS: { name: 'SMALL_BARS', label: 'Small Bars' }, LARGE_BARS: { name: 'LARGE_BARS', label: 'Large Bars' } };
export const BALCONY_TYPE = { BALCONY: 'BALCONY', GARDEN: 'GARDEN' };
const defaultSideWall = {
  enabled: true, height: 1, type: BALCONY_WALL_TYPE.SOLID.name, toCeil: false,
};
const defaultTopWall = {
  enabled: false, offsetY: 3, sameSizeAsFloor: true, sizeX: 3, sizeY: 0.1, sizeZ: 2, type: BALCONY_WALL_TYPE.SOLID.name,
};

const getSideWallHeightToCeil = (sideWall, currentState) => {
  let { height } = sideWall;
  if (sideWall.toCeil) {
    height = currentState.sideWallTop.offsetY;
  }
  return { ...sideWall, height };
};

const useBalconyStore = create(
  persist(
    immer((set) => ({
      balconyType: BALCONY_TYPE.BALCONY,
      setBalconyType: (balconyType) => set((state) => {
        state.balconyType = balconyType;
      }),
      sizeX: 4,
      setSizeX: (sizeX) => set((state) => {
        state.sizeX = sizeX;
      }),
      sizeZ: 3,
      setSizeZ: (sizeZ) => set((state) => {
        state.sizeZ = sizeZ;
      }),
      sideWallLeft: defaultSideWall,
      setSideWallLeft: (sideWallLeft) => set((state) => {
        state.sideWallLeft = getSideWallHeightToCeil(sideWallLeft, state);
      }),
      sideWallRight: defaultSideWall,
      setSideWallRight: (sideWallRight) => set((state) => {
        state.sideWallRight = getSideWallHeightToCeil(sideWallRight, state);
      }),
      sideWallFront: defaultSideWall,
      setSideWallFront: (sideWallFront) => set((state) => {
        state.sideWallFront = getSideWallHeightToCeil(sideWallFront, state);
      }),
      sideWallBack: { ...defaultTopWall, enabled: false },
      setSideWallBack: (sideWallBack) => set((state) => {
        state.sideWallBack = getSideWallHeightToCeil(sideWallBack, state);
      }),
      sideWallTop: { ...defaultTopWall, enabled: false },
      setSideWallTop: (sideWallTop) => set((state) => {
        if (sideWallTop.offsetY !== state.sideWallTop.offsetY) {
          if (state.sideWallLeft.toCeil)state.sideWallLeft.height = sideWallTop.offsetY;
          if (state.sideWallRight.toCeil)state.sideWallRight.height = sideWallTop.offsetY;
          if (state.sideWallFront.toCeil)state.sideWallFront.height = sideWallTop.offsetY;
          if (state.sideWallBack.toCeil)state.sideWallBack.height = sideWallTop.offsetY;
        }
        state.sideWallTop = sideWallTop;
        if (sideWallTop.sameSizeAsFloor) {
          state.sideWallTop.sizeX = state.sizeX;
          state.sideWallTop.sizeZ = state.sizeZ;
        }
      }),
    })),
    {
      name: 'balcony-store',
      getStorage: () => localStorage,
      blacklist: [''],
    },
  ),
);

export default useBalconyStore;
