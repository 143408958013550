import React, { useCallback, useEffect, useState } from 'react';
import * as THREE from 'three';
import { useLoader } from '@react-three/fiber';
import { useShallow } from 'zustand/react/shallow';
import { fetchBuildings } from '../../utils/osm';
import { createShape } from '../../utils/geometry';
import useUiStore, { SELECTION_MODE } from '../../stores/uiStore';
import { SELECTION_STATE } from './Buildings';

function Tree({ building, transparent }) {
  const trunkGeometry = new THREE.CylinderGeometry(0.5, 0.8, 8);
  const leafGeometry = new THREE.SphereGeometry(3);
  return (
    <group position={building.positionNormalized} key={building.id + transparent.toString()}>
      <mesh
        geometry={trunkGeometry}
        castShadow={!transparent}
        receiveShadow={!transparent}
        position={[0, 4, 0]}
      >
        {transparent ? (
          <meshStandardMaterial
            transparent={transparent}
            opacity={transparent ? 0.2 : 1}
            color="brown"
          />
        )
          : <meshStandardMaterial color="brown" />}
      </mesh>
      <mesh
        geometry={leafGeometry}
        castShadow={!transparent}
        receiveShadow={!transparent}
        position={[0, 9, 0]}
      >
        {transparent ? (
          <meshStandardMaterial
            transparent={transparent}
            opacity={transparent ? 0.2 : 1}
            color="green"
          />
        )
          : <meshStandardMaterial color="green" />}
      </mesh>
    </group>
  );
}

export default function Trees({ boundingBox }) {
  const [buildings, setBuildings] = useState([]);
  const [selectionMode] = useUiStore(useShallow((state) => [state.selectionMode]));

  useEffect(() => {
    async function fetchData() {
      const osmGreenFeatures = await fetchBuildings(boundingBox, `
          [out:json];
          (
            node["natural"="tree"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
            way["natural"="tree"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
            relation["natural"="tree"](${boundingBox.min.lat},${boundingBox.min.lon},${boundingBox.max.lat},${boundingBox.max.lon});
          );
          out body;
          >;
          out skel qt;
        `, 'Point');
      setBuildings(osmGreenFeatures.map((feature) => ({ ...feature, height: 1 })));
    }
    fetchData();
  }, [boundingBox]);

  const renderBuildings = useCallback(() => buildings.map((building, index) => (
    <Tree key={selectionMode + building.id} building={building} transparent={selectionMode === SELECTION_MODE.BALCONY_POSITION || selectionMode === SELECTION_MODE.BUILDING} />
  )), [buildings, selectionMode]);

  return (
    <>
      {renderBuildings()}
    </>
  );
}
