import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { Box, Typography } from '@mui/material';

export default function KeyValueLabel({ label, value }) {
  if (value === undefined) {
    return null;
  }

  return (
    <>
      <Typography gutterBottom variant="caption" fontWeight="">
        {label}
      </Typography>
      <Typography gutterBottom paddingLeft={0}>
        {value}
      </Typography>
    </>
  );
}
