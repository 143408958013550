import {
  SPACE_ATTRIBUTE,
  SUN_ATTRIBUTE,
  WATER_ATTRIBUTE,
} from '../utils/plantAttributeUtils';

export const PLANT_SPECIFICATION = [
  {
    id: 1,
    name: 'Cactus',
    image: 'https://images.unsplash.com/photo-1554631221-f9603e6808be?q=80&w=2317&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    info: 'Needs minimal water.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER: [WATER_ATTRIBUTE.FEW],
      SPACE: [SPACE_ATTRIBUTE.SMALL],
    },
  },
  {
    id: 2,
    name: 'Fern',
    image: 'https://upload.wikimedia.org/wikipedia/commons/3/32/OsmundaRegalis.jpg',
    info: 'Loves shade and humidity.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.SHADE],
      WATER: [WATER_ATTRIBUTE.MUCH],
      SPACE: [SPACE_ATTRIBUTE.MEDIUM],
    },
  },
  {
    id: 3,
    name: 'Sunflower',
    image: 'https://upload.wikimedia.org/wikipedia/commons/3/32/OsmundaRegalis.jpg',
    info: 'Prefers full sun.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER: [WATER_ATTRIBUTE.MEDIUM],
      SPACE: [SPACE_ATTRIBUTE.BIG],
    },
  },
  {
    id: 4,
    name: 'Rose',
    image: 'https://upload.wikimedia.org/wikipedia/commons/3/32/OsmundaRegalis.jpg',
    info: 'Requires well-fertilized soil.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.PARTIAL_SUN],
      WATER: [WATER_ATTRIBUTE.MEDIUM],
      SPACE: [SPACE_ATTRIBUTE.MEDIUM],
    },
  },
  {
    id: 5,
    name: 'Bamboo',
    image: 'https://upload.wikimedia.org/wikipedia/commons/3/32/OsmundaRegalis.jpg',
    info: 'Grows quickly and in many conditions.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.PARTIAL_SUN],
      WATER: [WATER_ATTRIBUTE.MUCH],
      SPACE: [SPACE_ATTRIBUTE.BIG],
    },
  },
  {
    id: 6,
    name: 'Lavender',
    image: 'https://upload.wikimedia.org/wikipedia/commons/3/32/OsmundaRegalis.jpg',
    info: 'Thrives in full sunlight and dry soils.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER: [WATER_ATTRIBUTE.FEW],
      SPACE: [SPACE_ATTRIBUTE.SMALL],
    },
  },
  {
    id: 7,
    name: 'Orchid',
    image: 'https://images.unsplash.com/photo-1565965656852-2b2689e318db',
    info: 'Requires indirect light and high humidity.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.PARTIAL_SUN],
      WATER: [WATER_ATTRIBUTE.MEDIUM],
      SPACE: [SPACE_ATTRIBUTE.SMALL],
    },
  },
  {
    id: 8,
    name: 'Marigold',
    image: 'https://images.unsplash.com/photo-1607746882042-944635dfe97e',
    info: 'Loves full sun and does well in almost any type of soil.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER: [WATER_ATTRIBUTE.MEDIUM],
      SPACE: [SPACE_ATTRIBUTE.SMALL],
    },
  },
  {
    id: 9,
    name: 'Tulip',
    image: 'https://images.unsplash.com/photo-1551446591-142875a901a1',
    info: 'Prefers cooler climates and full sun to partial shade.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.PARTIAL_SUN],
      WATER: [WATER_ATTRIBUTE.MEDIUM],
      SPACE: [SPACE_ATTRIBUTE.SMALL],
    },
  },
  {
    id: 10,
    name: 'Mint',
    image: 'https://images.unsplash.com/photo-1598280887172-6d89af71c3c7',
    info: 'Grows quickly and can take over the garden if not contained.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.PARTIAL_SUN],
      WATER: [WATER_ATTRIBUTE.MUCH],
      SPACE: [SPACE_ATTRIBUTE.SMALL],
    },
  },
  {
    id: 11,
    name: 'Tomato',
    image: 'https://images.unsplash.com/photo-1530731141654-5993c3016c77',
    info: 'Needs plenty of sunlight and water.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER: [WATER_ATTRIBUTE.MUCH],
      SPACE: [SPACE_ATTRIBUTE.MEDIUM],
    },
  },
  {
    id: 12,
    name: 'Sage',
    image: 'https://images.unsplash.com/photo-1603881566593-737628f43f93',
    info: 'Thrives in full sun and well-draining soil.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER: [WATER_ATTRIBUTE.FEW],
      SPACE: [SPACE_ATTRIBUTE.SMALL],
    },
  },
  {
    id: 13,
    name: 'Peony',
    image: 'https://images.unsplash.com/photo-1590337872344-385c59ea0db9',
    info: 'Prefers full sun and moist, well-drained soil.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER: [WATER_ATTRIBUTE.MEDIUM],
      SPACE: [SPACE_ATTRIBUTE.MEDIUM],
    },
  },
  {
    id: 14,
    name: 'Chrysanthemum',
    image: 'https://images.unsplash.com/photo-1567593813915-61952a536d67',
    info: 'Needs well-drained soil and lots of sun.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER: [WATER_ATTRIBUTE.MEDIUM],
      SPACE: [SPACE_ATTRIBUTE.MEDIUM],
    },
  },
  {
    id: 15,
    name: 'Lemon Tree',
    image: 'https://images.unsplash.com/photo-1598370058063-6c601a64371a',
    info: 'Requires full sun and regular watering.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.FULL_SUN],
      WATER: [WATER_ATTRIBUTE.MUCH],
      SPACE: [SPACE_ATTRIBUTE.BIG],
    },
  },
  {
    id: 16,
    name: 'Aloe Vera',
    image: 'https://images.unsplash.com/photo-1587802480717-1979d6b61e26',
    info: 'Prefers bright, indirect sunlight and occasional watering.',
    optimalAttributes: {
      SUN: [SUN_ATTRIBUTE.PARTIAL_SUN],
      WATER: [WATER_ATTRIBUTE.FEW],
      SPACE: [SPACE_ATTRIBUTE.SMALL],
    },
  },
];
