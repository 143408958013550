import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useShallow } from 'zustand/react/shallow';
import useScenarioStore from '../../stores/scenarioStore';
import useEntitiesStore, {
  ENTITY_PLACE_TYPE as PLACE_TYPE,
  ENTITY_TYPE,
} from '../../stores/entitiesStore';
import Plant from '../../components/3d/TomatoPlant';
import Table from '../../components/3d/Table';
import useBalconyStore from '../../stores/balconyStore';
import PlantPotSingle from '../../components/3d/PlantPotSingle';
import PlantContainerTwo from '../../components/3d/PlantContainerTwo';
import Umbrella from '../../components/3d/Umbrella';
import Chair from '../../components/3d/Chair';
import useEntityPosition from '../../hooks/useEntityPosition';
import Bench from '../../components/3d/Bench';
import Sunblind from '../../components/3d/Sunblind';

function Entity({ entity }) {
  const [updateEntityPartly] = useEntitiesStore(useShallow((state) => [state.updateEntityPartly]));
  const [
    loadedBuildings,
  ] = useScenarioStore(useShallow((state) => [state.loadedBuildings]));

  const placeOnTop = ENTITY_TYPE[entity.type].placeOnTop || false;
  const position = useEntityPosition(
    entity.positionX,
    entity.positionZ,
    entity.placeType,
    entity.width,
    entity.length,
    entity.id,
    placeOnTop,
  );

  let EntityComponent = null;
  if (entity.type === ENTITY_TYPE.PLANT_POT.name) {
    EntityComponent = <PlantPotSingle />;
  } else if (entity.type === ENTITY_TYPE.PLANT_CONTAINER_TWO.name) {
    EntityComponent = <PlantContainerTwo />;
  } else if (entity.type === ENTITY_TYPE.TABLE.name) {
    EntityComponent = (
      <Table
        colorTop={entity.colorAttributes.TOP}
        colorLegs={entity.colorAttributes.LEGS}
        length={entity.nummericAttributes?.LENGTH}
        width={entity.nummericAttributes?.WIDTH}
      />
    );
  } else if (entity.type === ENTITY_TYPE.CHAIR.name) {
    EntityComponent = (
      <Chair
        colorSeat={entity.colorAttributes.SEAT}
        colorLegs={entity.colorAttributes.LEGS}
        length={entity.nummericAttributes?.LENGTH}
        width={entity.nummericAttributes?.WIDTH}
      />
    );
  } else if (entity.type === ENTITY_TYPE.BENCH.name) {
    EntityComponent = (
      <Bench
        colorSeat={entity.colorAttributes.SEAT}
        colorLegs={entity.colorAttributes.LEGS}
        length={entity.nummericAttributes?.LENGTH}
        width={entity.nummericAttributes?.WIDTH}
      />
    );
  } else if (entity.type === ENTITY_TYPE.UMBRELLA.name) {
    EntityComponent = (
      <Umbrella
        variant={entity.variant}
        handleHeight={entity.nummericAttributes.HEIGHT}
        open={entity.nummericAttributes.OPEN}
        colorTop={entity.colorAttributes.TOP}
        colorHandle={entity.colorAttributes.HANDLE}
      />
    );
  } else if (entity.type === ENTITY_TYPE.SUNBLIND.name) {
    EntityComponent = (
      <Sunblind
        heightPosition={entity.nummericAttributes.HEIGHT_POSITION}
        tiltAngle={entity.nummericAttributes.TILT_ANGLE}
        maximalLength={entity.nummericAttributes.MAXIMAL_LENGTH}
        width={entity.nummericAttributes.WIDTH}
        open={entity.nummericAttributes.OPEN}
        colorTop={entity.colorAttributes.TOP}
        colorPole={entity.colorAttributes.POLE}
        positionZModified={entity.positionZ > 0}
      />
    );
  } else {
    console.error(`Unknown entity type: ${entity.type}`);
  }

  useEffect(() => {
    if (!loadedBuildings) return;
    if ((position || []).join() !== (entity.position || []).join()) {
      updateEntityPartly(entity.id, {
        balconyPosition: {
          x: position[0],
          y: position[1],
          z: position[2],
        },
      });
    }
  }, [position, loadedBuildings]);

  return (
    <group
      key={entity.id}
      name={`entity_${entity.id}`}
      position={position}
      rotation={[0, entity.rotationY * (Math.PI / 180), 0]}
      userData={{ placedOnTop: placeOnTop }}
    >
      {EntityComponent}
    </group>
  );
}

export default function BalconyEntities({}) {
  const [entities] = useEntitiesStore(useShallow((state) => [state.entities]));

  return (
    <>
      {entities.map((entity) => <Entity key={entity.id} entity={entity} />)}
    </>
  );
}
