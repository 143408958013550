/* eslint-disable no-param-reassign */
import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import dayjs from 'dayjs';
import { persist } from 'zustand/middleware';

export const PLANT_TYPE = {
  FLOWER: {
    id: 'FLOWER', name: 'FLOWER', label: 'Flower',
  },
  BAMBUS: {
    id: 'BAMBUS', name: 'BAMBUS', label: 'Bambus',
  },
};

const defaultPlant = {
  attachedEntityId: null,
  attachedIndexPosition: null,
  specificationId: null,
  age: 1,
};

const usePlantsStore = create(
  persist(
    immer((set) => ({
      plants: [],
      setPlants: (plants) => set((state) => {
        state.plants = plants;
      }),
      addPlant: (plant) => set((state) => {
        const numberWithSameType = state.plants.filter((e) => e.type === plant.type);
        const enumType = PLANT_TYPE[Object.keys(PLANT_TYPE).find((key) => PLANT_TYPE[key].name === plant.type)];
        state.plants.unshift({
          ...defaultPlant,
          type: plant.type,
          name: `${enumType.label} ${numberWithSameType.length + 1}`,
          id: plant.id || Math.round(Math.random() * 100000),
          attachedEntityId: plant.attachedEntityId,
          attachedIndexPosition: plant.attachedIndexPosition,
        });
      }),
      updatePlantPartly: (id, plant) => set((state) => {
        const foundPlant = state.plants.find((e) => e.id === id);
        if (!foundPlant) {
          throw new Error(`Plant not found: ${id}`);
        }
        state.plants = state.plants.map((e) => (e.id === id ? { ...e, ...plant } : e));
      }),
      removePlant: (id) => set((state) => {
        state.plants = state.plants.filter((plant) => plant.id !== id);
      }),
      handleEntityWillBeRemoved: (id) => set((state) => {
        state.plants = state.plants.map((plant) => {
          if (plant.attachedEntityId === id) {
            plant.attachedEntityId = null;
            plant.attachedIndexPosition = null;
          }
          return plant;
        });
      }),
    })),
    {
      name: 'plants-store',
      getStorage: () => localStorage,
      blacklist: [''],
    },
  ),
);

export default usePlantsStore;
