import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import useScenarioStore from '../stores/scenarioStore';

export default function CustomDateTimePicker({ showAnimate = false }) {
  const { selectedDateTime, setSelectedDateTime } = useScenarioStore();
  const [animate, setAnimate] = React.useState(false);

  React.useEffect(() => {
    let intervalId;
    if (animate) {
      intervalId = setInterval(() => {
        setSelectedDateTime(dayjs(selectedDateTime).add(10, 'm').toDate());
      }, 200);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [animate, setSelectedDateTime, selectedDateTime]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={dayjs(selectedDateTime)}
        onChange={(value) => setSelectedDateTime(value.toDate())}
        ampm={false}
        formatDensity="dense"
        slotProps={{ textField: { size: 'small' } }}
      />
      {showAnimate && (
        <FormControlLabel
          control={<Checkbox checked={animate} onChange={(e) => setAnimate(e.target.checked)} />}
          label="Animate"
        />
      )}
    </LocalizationProvider>
  );
}
