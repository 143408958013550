/* eslint-disable no-param-reassign */
import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import dayjs from 'dayjs';
import { persist } from 'zustand/middleware';
import {
  Blind,
  Chair, ChairAlt, LocalFlorist, TableBar, Umbrella,
} from '@mui/icons-material';
import { Table } from '@mui/material';

export const ENTITY_CATEGORY = {
  PLANT_CONTAINER: {
    id: 'PLANT_CONTAINER',
    name: 'PLANT_CONTAINER',
    label: 'Plant container',
    Icon: LocalFlorist,
  },
  FURNITURE: {
    id: 'FURNITURE',
    name: 'FURNITURE',
    label: 'Furniture',
    Icon: TableBar,
  },
  SUN_PROTECTION: {
    id: 'SUN_PROTECTION',
    name: 'SUN_PROTECTION',
    label: 'Sun protection',
    Icon: Umbrella,
  },
};

export const ENTITY_TYPE = {
  PLANT_POT: {
    id: 'PLANT_POT',
    name: 'PLANT_POT',
    label: 'Plant pot',
    placeOnTop: true,
    category: ENTITY_CATEGORY.PLANT_CONTAINER,
    Icon: LocalFlorist,
    calculateShade: true,
    plantPositions: [{
      index: 0,
      name: 'Center',
      x: 0,
      y: 0.1,
      z: 0,
    }],
  },
  PLANT_CONTAINER_TWO: {
    id: 'PLANT_CONTAINER_TWO',
    name: 'PLANT_CONTAINER_TWO',
    label: 'Plant container (2)',
    placeOnTop: true,
    category: ENTITY_CATEGORY.PLANT_CONTAINER,
    Icon: LocalFlorist,
    calculateShade: true,
    plantPositions: [{
      index: 0,
      name: 'Left',
      x: -0.15,
      y: 0.1,
      z: 0,
    }, {
      index: 1,
      name: 'Right',
      x: 0.15,
      y: 0.1,
      z: 0,
    }],
  },
  UMBRELLA: {
    id: 'UMBRELLA',
    name: 'UMBRELLA',
    label: 'Umbrella',
    placeOnTop: true,
    category: ENTITY_CATEGORY.SUN_PROTECTION,
    Icon: Umbrella,
    variants: [{ id: 'ROUND', name: 'Round' }, { id: 'SQUARE', name: 'Square' }],
    nummericAttributes: [{
      id: 'HEIGHT', name: 'Height', min: 1, max: 3, step: 0.1, default: 2,
    }, {
      id: 'OPEN', name: 'Open', min: 0, max: 1, step: 0.1, default: 1,
    },
    ],
    colorAttributes: [{
      id: 'HANDLE', name: 'Handle', default: '#fdfdfd',
    }, {
      id: 'TOP', name: 'Top', default: '#ff8c00',
    },
    ],
  },
  SUNBLIND: {
    id: 'SUNBLIND',
    name: 'SUNBLIND',
    label: 'Sunblind',
    placeOnTop: false,
    category: ENTITY_CATEGORY.SUN_PROTECTION,
    Icon: Blind,
    width: 1,
    length: 0.001,
    disableAttachmentSelection: true,
    disableRotation: true,
    // disablePositionZ: true,
    variants: [],
    nummericAttributes: [{
      id: 'HEIGHT_POSITION', name: 'Height Position', min: 1, max: 5, step: 0.1, default: 3,
    }, {
      id: 'TILT_ANGLE', name: 'Tilt angle', min: 0.0, max: 1, step: 0.1, default: 1,
    }, {
      id: 'MAXIMAL_LENGTH', name: 'Maximal length', min: 0.5, max: 5, step: 0.1, default: 3,
    }, {
      id: 'WIDTH', name: 'Width', min: 0.5, max: 15, step: 0.1, default: 3,
    }, {
      id: 'OPEN', name: 'Open', min: 0.01, max: 1, step: 0.01, default: 1,
    },
    ],
    colorAttributes: [{
      id: 'POLE', name: 'Pole', default: '#939393',
    }, {
      id: 'TOP', name: 'Top', default: '#ff8c00',
    },
    ],
  },
  TABLE: {
    id: 'TABLE',
    name: 'TABLE',
    label: 'Table',
    category: ENTITY_CATEGORY.FURNITURE,
    Icon: TableBar,
    nummericAttributes: [{
      id: 'WIDTH', name: 'Width', min: 0.2, max: 5, step: 0.1, default: 2,
    }, {
      id: 'LENGTH', name: 'Length', min: 0.2, max: 5, step: 0.1, default: 1,
    },
    ],
    colorAttributes: [{
      id: 'LEGS', name: 'Legs', default: '#000000',
    }, {
      id: 'TOP', name: 'Top', default: '#ff8c00',
    },
    ],
  },
  CHAIR: {
    id: 'CHAIR',
    name: 'CHAIR',
    label: 'Chair',
    category: ENTITY_CATEGORY.FURNITURE,
    Icon: Chair,
    nummericAttributes: [{
      id: 'WIDTH', name: 'Width', min: 0.2, max: 5, step: 0.1, default: 1.2,
    }, {
      id: 'LENGTH', name: 'Length', min: 0.2, max: 5, step: 0.1, default: 0.6,
    },
    ],
    colorAttributes: [{
      id: 'LEGS', name: 'Legs', default: '#bababa',
    }, {
      id: 'SEAT', name: 'Seat', default: '#ddb890',
    },
    ],
  },
  BENCH: {
    id: 'BENCH',
    name: 'BENCH',
    label: 'Bench',
    category: ENTITY_CATEGORY.FURNITURE,
    Icon: ChairAlt,
    nummericAttributes: [{
      id: 'WIDTH', name: 'Width', min: 0.2, max: 5, step: 0.1, default: 0.5,
    }, {
      id: 'LENGTH', name: 'Length', min: 0.2, max: 5, step: 0.1, default: 0.6,
    },
    ],
    colorAttributes: [{
      id: 'LEGS', name: 'Legs', default: '#bababa',
    }, {
      id: 'SEAT', name: 'Seat', default: '#ddb890',
    },
    ],
  },
};

export const ENTITY_PLACE_TYPE = { FLOOR: { name: 'FLOOR', label: 'Floor' }, SIDEWALL: { name: 'SIDEWALL', label: 'Sidewall' } };

const defaultEntity = {
  height: 0.7,
  type: ENTITY_TYPE.PLANT_POT.name,
  placeType: ENTITY_PLACE_TYPE.FLOOR.name,
  width: 1,
  length: 1,
  positionX: 0,
  positionZ: 0,
  rotationY: 0,
};

const useEntitiesStore = create(
  persist(
    immer((set) => ({
      entities: [],
      setEntities: (entities) => set((state) => {
        state.entities = entities;
      }),
      addEntity: (entity) => set((state) => {
        const numberWithSameType = state.entities.filter((e) => e.type === entity.type);
        const enumType = ENTITY_TYPE[Object.keys(ENTITY_TYPE).find((key) => ENTITY_TYPE[key].name === entity.type)];

        const newEntity = {
          ...defaultEntity,
          length: ENTITY_TYPE[entity.type].length || defaultEntity.length,
          width: ENTITY_TYPE[entity.type].width || defaultEntity.width,
          type: entity.type,
          name: `${enumType.label} ${numberWithSameType.length + 1}`,
          id: entity.id || Math.round(Math.random() * 100000),
        };

        if (ENTITY_TYPE[entity.type].variants?.length) {
          newEntity.variant = ENTITY_TYPE[entity.type].variants[0].id;
        }

        if (ENTITY_TYPE[entity.type].nummericAttributes) {
          newEntity.nummericAttributes = Object.fromEntries(
            ENTITY_TYPE[entity.type].nummericAttributes.map((attr) => [attr.id, attr.default]),
          );
        }

        if (ENTITY_TYPE[entity.type].colorAttributes) {
          newEntity.colorAttributes = Object.fromEntries(
            ENTITY_TYPE[entity.type].colorAttributes.map((attr) => [attr.id, attr.default]),
          );
        }

        state.entities.unshift(newEntity);
      }),
      updateEntityPartly: (id, entity) => set((state) => {
        const foundEntity = state.entities.find((e) => e.id === id);
        if (!foundEntity) {
          throw new Error(`Entity not found: ${id}`);
        }
        state.entities = state.entities.map((e) => (e.id === id ? { ...e, ...entity } : e));
      }),
      removeEntity: (id) => set((state) => {
        state.entities = state.entities.filter((entity) => entity.id !== id);
      }),
    })),
    {
      name: 'entities-store',
      getStorage: () => localStorage,
      blacklist: [''],
    },
  ),
);

export default useEntitiesStore;
