import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent, IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import SunCalc from 'suncalc';
import * as THREE from 'three';
import { debounce } from 'lodash';
import { useShallow } from 'zustand/react/shallow';
import { Info } from '@mui/icons-material';
import dayjs from 'dayjs';
import useScenarioStore from '../stores/scenarioStore';
import { getInShade, getSunPosition } from '../utils/geoCalculationUtils';
import { ThreeContext } from '../context/ThreeContext';
import useUiStore from '../stores/uiStore';
import useShadeCalculation, { SHADE_CALCULATION_RANGES } from '../hooks/useShadeCalculation';
import { getSunFactor } from '../utils/plantAttributeUtils';

export default function ShadeCard({ selectedObject }) {
  const { updatedScenarioFlag, triggerUpdatedScenarioFlag } = useScenarioStore();
  const [averageShade, setAverageShade] = useState(-1);
  const { scene } = useContext(ThreeContext);
  const [monthsRange, setMonthsRange] = useState(SHADE_CALCULATION_RANGES.SUMMER_HALF);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openShadeDetails] = useUiStore(useShallow((state) => [state.openShadeDetails]));
  const shade = useShadeCalculation();

  const calculateShade = useRef(debounce(
    () => setAverageShade(1.0 - shade.calculateShade(selectedObject, [{
      date: dayjs('2023-04-29T12:00:00').toDate(),
    }, {
      date: dayjs('2023-06-15T12:00:00').toDate(),
    }, {
      date: dayjs('2023-09-01T12:00:00').toDate(),
    },
    ], selectedObject.attachedEntityId ? [selectedObject.attachedEntityId] : []).totalShade),
    600,
  ));

  useEffect(() => {
    calculateShade.current(selectedObject, monthsRange);
  }, [selectedObject, updatedScenarioFlag, monthsRange]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    setAnchorEl(null);
    if (value) {
      setMonthsRange(value);
    }
  };

  const handleInfoClick = () => {
    openShadeDetails(selectedObject.id);
  };

  const shadeClassification = useMemo(() => (averageShade === -1 ? null : getSunFactor(averageShade).label), [averageShade]);

  return (
    <Box>
      <Typography variant="body2" onClick={handleInfoClick} sx={{ cursor: 'pointer' }}>
        {shadeClassification || '-'}
        {' '}
        (
        {averageShade === -1 ? '-' : (averageShade * 100).toFixed(0)}
        %)
        <IconButton onClick={handleInfoClick} size="small">
          <Info fontSize="small" />
        </IconButton>
      </Typography>
      <LinearProgress
        variant="determinate"
        value={averageShade * 100}
        sx={{
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#ffd000',
          },
          backgroundColor: 'darkgrey',
        }}
      />
      {/* <Button onClick={handleClick}> */}
      {/*  {monthsRange.label} */}
      {/* </Button> */}
      {/* <Menu */}
      {/*  anchorEl={anchorEl} */}
      {/*  keepMounted */}
      {/*  open={Boolean(anchorEl)} */}
      {/*  onClose={() => handleClose()} */}
      {/* > */}
      {/*  {Object.values(SHADE_CALCULATION_RANGES).map((range) => ( */}
      {/*    <MenuItem key={range.value} onClick={() => handleClose(range)}> */}
      {/*      {range.label} */}
      {/*    </MenuItem> */}
      {/*  ))} */}
      {/* </Menu> */}
    </Box>
  );
}
