import React, { useEffect, useState } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { useShallow } from 'zustand/react/shallow';
import useUiStore from '../stores/uiStore';
import useSelectionModeClick from '../hooks/useSelectionModeClick';
import { degreesToRadians } from '../utils/geoCalculationUtils';

export default function UiHelper() {
  const useSelectionModeClick1 = useSelectionModeClick();

  const ZOOM = 18;
  const { camera, scene } = useThree();
  const [selectedPlace, selectionMode] = useUiStore(useShallow((state) => [state.selectedPlace, state.selectionMode]));
  const [tiles, setTiles] = useState([]);

  const EARTH_RADIUS = 6378137; // Earth's radius in meters
  const EARTH_CIRCUMFERENCE = 2 * Math.PI * EARTH_RADIUS; // Earth's circumference in meters

  // Convert tile Y coordinate to latitude
  function tile2lat(y, z) {
    const n = Math.PI - (2.0 * Math.PI * y) / 2.0 ** z;
    return (180.0 / Math.PI) * Math.atan(Math.sinh(n));
  }

  // Convert latitude to Y in Web Mercator projection
  function lat2y(lat) {
    return EARTH_RADIUS * Math.log(Math.tan(Math.PI / 4 + lat * Math.PI / 360));
  }

  // Convert longitude to X in Web Mercator projection
  function lon2x(lon) {
    return EARTH_RADIUS * lon * Math.PI / 180;
  }

  // Convert tile X coordinate to longitude
  function tile2lon(x, z) {
    return x / 2.0 ** z * 360.0 - 180.0;
  }

  // Calculate tile size in meters
  function tileSizeInMeters(tileX, tileY, zoom) {
    const numTiles = 2 ** zoom;
    const widthInMeters = EARTH_CIRCUMFERENCE / numTiles;

    const latTop = tile2lat(tileY, zoom);
    const latBottom = tile2lat(tileY + 1, zoom);

    const yTop = lat2y(latTop);
    const yBottom = lat2y(latBottom);

    const heightInMeters = Math.abs(yTop - yBottom);

    return {
      width: widthInMeters,
      height: heightInMeters,
    };
  }

  function getTileOffsetInMeters(selectedPlace, zoom) {
    const tileX = Math.floor((selectedPlace.lon + 180) / 360 * 2 ** zoom);
    const tileY = Math.floor((1 - Math.log(Math.tan(selectedPlace.lat * Math.PI / 180) + 1 / Math.cos(selectedPlace.lat * Math.PI / 180)) / Math.PI) / 2 * 2 ** zoom);

    const topLeftLat = tile2lat(tileY, zoom);
    const topLeftLon = tile2lon(tileX, zoom);

    const topLeftX = lon2x(topLeftLon);
    const topLeftY = lat2y(topLeftLat);

    const selectedPlaceX = lon2x(selectedPlace.lon);
    const selectedPlaceY = lat2y(selectedPlace.lat);

    const offsetX = selectedPlaceX - topLeftX;
    const offsetY = topLeftY - selectedPlaceY;

    return {
      offsetX,
      offsetY,
    };
  }

  async function loadOpenStreetMapTexture(zoom, x, y) {
    const url = `https://tile.openstreetmap.org/${zoom}/${x}/${y}.png`;

    return new Promise((resolve, reject) => {
      new THREE.TextureLoader().load(url, (texture) => {
        resolve(texture);
      }, undefined, (error) => {
        reject(error);
      });
    });
  }

  // useEffect(() => {
  //   if (selectedPlace) {
  //     const zoom = 17;
  //     const tileX = Math.floor((selectedPlace.lon + 180) / 360 * 2 ** zoom);
  //     const tileY = Math.floor((1 - Math.log(Math.tan(selectedPlace.lat * Math.PI / 180) + 1 / Math.cos(selectedPlace.lat * Math.PI / 180)) / Math.PI) / 2 * 2 ** zoom);
  //
  //     const offset = getTileOffsetInMeters(selectedPlace, zoom);
  //     console.log(offset);
  //     const newTiles = [];
  //     const group = new THREE.Group();
  //     for (let x = tileX - 2; x <= tileX + 2; x++) {
  //       for (let y = tileY - 2; y <= tileY + 2; y++) {
  //         loadOpenStreetMapTexture(zoom, x, y).then((texture) => {
  //           const tileSize = tileSizeInMeters(tileX, tileY, zoom + 1);
  //
  //           const geometry = new THREE.BoxGeometry(1, 1, 1);
  //           const material = new THREE.MeshBasicMaterial({ map: texture });
  //           const box = new THREE.Mesh(geometry, material);
  //
  //           box.scale.set(tileSize.width, 1, tileSize.height);
  //           box.position.set(-offset.offsetX + (x - tileX) * tileSize.width, 9, -offset.offsetY + (y - tileY) * tileSize.height);
  //
  //           newTiles.push(box);
  //           group.add(box);
  //         });
  //       }
  //     }
  //     scene.add(group);
  //     group.rotateY(degreesToRadians(-90));
  //
  //     setTiles(newTiles);
  //   }
  //   return () => {
  //     tiles.forEach((tile) => {
  //       scene.remove(tile);
  //     });
  //   };
  // }, [selectedPlace]);

  return null;
}
