import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Checkbox,
  Fab,
  FormControlLabel,
  FormGroup,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Visibility, VisibilityOff,
} from '@mui/icons-material';
import { useShallow } from 'zustand/react/shallow';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import useScenarioStore from '../stores/scenarioStore';
import CustomDateTimePicker from './DateTimePicker';
import useUiStore from '../stores/uiStore';

export default function DateTimeSlider() {
  const { selectedDateTime, setSelectedDateTime } = useScenarioStore();
  const [animate, setAnimate] = React.useState(false);
  const [addNewEvent, showShadowLayer, setShowShadowLayer] = useUiStore(useShallow((state) => [state.addNewEvent, state.showShadowLayer, state.setShowShadowLayer]));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleShadowLayerToggle = () => {
    setShowShadowLayer(!showShadowLayer);
  };

  React.useEffect(() => {
    let intervalId;
    if (animate) {
      intervalId = setInterval(() => {
        setSelectedDateTime(dayjs(selectedDateTime).add(10, 'm').toDate());
      }, 200);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [animate, setSelectedDateTime, selectedDateTime]);

  return (
    <Box display="flex">
      <Box sx={{
        width: '30%',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        paddingLeft: 1,
      }}
      >
        <Button
          variant={showShadowLayer ? 'contained' : 'outlined'}
          onClick={handleShadowLayerToggle}
          size={isMobile ? 'small' : 'medium'}
          startIcon={(showShadowLayer ? <Visibility /> : <VisibilityOff />)}
        >
          {showShadowLayer ? 'Shadow' : 'Shadow'}
          {isMobile ? null : ' Layer'}
        </Button>
        {/* <FormGroup> */}
        {/*  <FormControlLabel control={<Checkbox checked={showShadowLayer} onChange={handleShadowLayerToggle} />} label="Shadow Layer" /> */}
        {/* </FormGroup> */}
        {/* <IconButton size="small" onClick={() => setSelectedDateTime(dayjs(selectedDateTime).hour(9).minute(0).toDate())}> */}
        {/*  M */}
        {/* </IconButton> */}
        {/* <IconButton size="small" onClick={() => setSelectedDateTime(dayjs(selectedDateTime).hour(12).minute(0).toDate())}> */}
        {/*  N */}
        {/* </IconButton> */}
        {/* <IconButton size="small" onClick={() => setSelectedDateTime(dayjs(selectedDateTime).hour(18).minute(0).toDate())}> */}
        {/*  E */}
        {/* </IconButton> */}
      </Box>
      {/* <Box sx={{ */}
      {/*  width: '60%', height: '55px', display: 'flex', justifyContent: 'center', alignItems: 'center', */}
      {/* }} */}
      {/* /> */}
      <Box sx={{
        width: '70%', display: 'flex', justifyContent: 'end', alignItems: 'center', pr: 1,
      }}
      >
        {/* <IconButton onClick={() => setSelectedDateTime(dayjs(selectedDateTime).subtract(1, 'M').toDate())}> */}
        {/*  <KeyboardDoubleArrowLeft /> */}
        {/* </IconButton> */}
        {!isMobile && (
          <IconButton onClick={() => setSelectedDateTime(dayjs(selectedDateTime).subtract(7, 'd').toDate())}>
            <KeyboardArrowLeft />
          </IconButton>
        )}

        <CustomDateTimePicker />

        {!isMobile && (
          <IconButton onClick={() => setSelectedDateTime(dayjs(selectedDateTime).add(7, 'd').toDate())}>
            <KeyboardArrowRight />
          </IconButton>
        )}

        {/* <IconButton onClick={() => setSelectedDateTime(dayjs(selectedDateTime).add(1, 'M').toDate())}> */}
        {/*  <KeyboardDoubleArrowRight /> */}
        {/* </IconButton> */}

        {!isMobile && (
          <>
            <IconButton size="medium" onClick={() => setSelectedDateTime(dayjs(selectedDateTime).subtract(1, 'h').toDate())}>
              -
            </IconButton>
            <IconButton size="medium" onClick={() => setSelectedDateTime(dayjs(selectedDateTime).add(1, 'h').toDate())}>
              +
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
}
